import {Link} from 'react-router-dom'
import LOGO from "../static/images/JF2.png";

const Dashboard = () => {
    return(
        <>
        <div style={{height: '100vh', width: '100%', display: 'flex' , justifyContent: 'center' , alignItems: 'center' , backgroundColor: 'black'}}> 
        <div style={{textAlign: 'center'}}>
           <img src={LOGO} alt = "" style={{height: '150px'}}></img>
           <p style={{color: 'white'}}>$JUNGLE Dashboard coming soon</p>
           <Link to='/' style={{color: '#F59E0C'}}>Back To Homepage</Link>
        </div>
        </div>
        </>
    )
}

export default Dashboard
