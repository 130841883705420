import { useState, useEffect } from "react";
import Web3Modal from "web3modal";
import WalletConnect from "@walletconnect/web3-provider";
import Torus from "@toruslabs/torus-embed";
import { ethers } from "ethers";
import WalletLink from "walletlink";

const infuraId = process.env.INFURA_API_KEY;
const providerOptions = {
  walletconnect: {
    package: WalletConnect,
    options: {
      infuraId,
      rpc: {
        4: "https://rinkeby.infura.io/v3/607f37db34664d4ca32d911fb2639a24",
        1: "https://mainnet.infura.io/v3/20cb9ff2e7ec4ddab0176cfa087bbe13",
      },
    },
  },

  torus: {
    package: Torus,
  },

  walletlink: {
    package: WalletLink,
    options: {
      appName: "Jungle Freaks",
      infuraId,
    },
  },
};
const web3Modal = new Web3Modal({
  network: "rinkeby",
  cacheProvider: true,
  providerOptions,
});

const INITIAL_STATE = {
  signer: null,
  provider: null,
  address: "",
  chainId: 1,
  networkId: 1,
  connected: false,
};

const initEthers = (instance) => {
  return new ethers.providers.Web3Provider(instance);
};

export default function useWeb3Connect() {
  const [state, setState] = useState(INITIAL_STATE);

  const resetApp = async () => {
    await web3Modal.clearCachedProvider();
    setState(INITIAL_STATE);
  };

  const onConnect = async () => {
    let provider;

    try {
      const instance = await web3Modal.connect();
      provider = initEthers(instance);
    } catch (e) {
      resetApp();
      return false;
    }

    const signer = provider.getSigner();
    const { networkId, chainId } = await provider.getNetwork();

    setState({
      signer,
      provider,
      address: await signer.getAddress(),
      chainId,
      networkId,
      connected: true,
    });
    return true;
  };

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      onConnect();
    }
  }, []);

  return { onConnect, web3Modal, ...state };
}
