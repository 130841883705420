import LOGO from "../static/images/logo.svg";
import HAMBURGER from "../static/images/icons/hamburger-icon.svg";
import styled from "styled-components";
import { Link } from "react-router-dom";


const NavBar = styled.div`
  background: rgba(0, 0, 0, 0.7);
  background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 10%, rgba(0,0,0,0) 100%);
  padding-left: 4%;
  padding-right: 4%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "left_header right_header right_header";
  align-items: center;
  position: relative;
  z-index: 2;
`;

const McNavbar = () => {
  const toogleMenu = () => {
    let menu_toggle = document.getElementById("toogle-menu");
    if (menu_toggle.style.display === "none") {
      menu_toggle.style.display = "block";
      menu_toggle.style.transition = "0.4";
      menu_toggle.style.maxHeight = "max-content";
    } else {
      menu_toggle.style.maxHeight = "0px";
      menu_toggle.style.display = "none";
    }
  }

  return (
    <NavBar>
      <Link to="/">
        <div className="head-logo">
          <img src={LOGO} alt="" />
        </div>
      </Link>
      <div className="menu-header-grid">
        <div className="hamburger" onClick={() => toogleMenu()}>
          <img src={HAMBURGER} alt="" />
        </div>

        <nav id="toogle-menu" className="head-menu red">
          <ul>
            <li>
              <Link to="/vehicle-for-change">
                <button>A VEHICLE FOR CHANGE</button>
              </Link>
            </li>
            <li>
              <a href="https://medium.com/@junglefreaksnft">
                <button>BLOG</button>
              </a>
            </li>
            <li>
              <Link to="/#jungle">
                <button>$JUNGLE</button>
              </Link>
            </li>
            <li>
              <a target="_blank" href="https://shop.junglefreaks.io/">
                <button>SHOP</button>
              </a>
            </li>
            <li>
              <Link to="/jfmc">
                <button>JFMC</button>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </NavBar>
  );
}

export default McNavbar;