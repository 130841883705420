import MC_LOGO from "../static/images/jfmc-logo-lg.png";
import LIST_STYLE from "../static/images/icons/mc-list-style.png";
import styled from "styled-components";
import { Link } from "react-router-dom";

const WhyList = styled.div`
  font-size: 16px;
  line-height: 30px;
  & > h5 {
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    margin: 30px 0 0 0;
    & img {
      margin-right: 20px;
    }
    & span {
      font-weight: normal;
      color: #999;
    }
    & a {
      color: #999;
    }
  }
  & > ul {
    margin-top: 0;
    margin-left: 20px;
    color: #999;
  }
`;

const LogoImg = styled.img`
  max-width: 750px;
`;

const GridWhy = styled.div`
  padding: 0 50px 90px 50px;
  display: grid;
  align-items: center;
  grid-template-columns: 1.1fr 0.9fr;
  gap: 3rem;
  & img {
    cursor: pointer;
  }
  & h1 {
    font-weight: 800;
    font-size: 50px;
    margin-top: 0;
    line-height: 43px;
  }
  & > * {
    width: 100%;
  }
  @media screen and (max-width: 900px) {
    padding-right: 5%;
    padding-left: 5%;
    grid-template-columns: 1fr;
    & ${WhyList} {
      & ul {
        margin-left: 0;
      }
      & img {
        display: none;
      }
    }
  }
`;

const McHeroWhy = () => {
  return (
    <GridWhy>
      <LogoImg src={MC_LOGO} alt="" />
      <div>
        <h1>WHY JFMC?</h1>
        <WhyList>
          <h5>
            <img src={LIST_STYLE} alt="" />
            First Ever Hand Drawn Generative Car NFT Collection
          </h5>
          <ul>
            <li>5 Unique Car Styles</li>
            <li>Over 350+ Hand Drawn Traits</li>
          </ul>
          <h5>
            <img src={LIST_STYLE} alt="" />
            Access to all future racing games in the Jungle Freaks Ecosystem
          </h5>
          <ul>
            <li>P2E in Netvrk Metaverse ( Trailer coming soon!)</li>
            <li>P2E in NFT Worlds Metaverse</li>
            <li>And more!</li>
          </ul>
          <h5>
            <img src={LIST_STYLE} alt="" />
            Preferential Treatment for "Allow List" on JF Gen 2 - FALLOUT FREAKS
          </h5>
        </WhyList>
      </div>
    </GridWhy>
  );
};

export default McHeroWhy;
