import React from "react";
import { Description, Form, Title } from "./McClaimForm";
import ALERT_SUCCESS from "../static/images/icons/alert-success.png";
import styled from "styled-components";
import TESLA from "../static/images/tesla-bg.svg";
import ETH from "../static/images/eth-bg.svg";

const AlertMsgIcon = styled.img`
  width: 41px;
  margin-bottom: 16px;
`;

const ImgBg = styled.div`
  position: absolute;
  display: flex;
  left: 0;
  top: 20px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  opacity: 0.4;

  & img {
    height: 120%;
  }
`;

const Content = styled.div`
  position: relative;
  margin-top: 20px;
`;

const Description2 = styled(Description)`
  margin-bottom: 80px;
  & p {
    padding-bottom: 20px;
  }
`;

const Nowrap = styled.span`
  white-space: nowrap;
`;

const McClaimFinish = ({ prize, form }) => {
  const isTesla = prize === 'Tesla';
  const IMG = isTesla ? TESLA : ETH;

  return (
    <>
      <Form hasHeader={true}>
        <Content>
          <ImgBg>
            <img src={IMG} alt="" />
          </ImgBg>
          <AlertMsgIcon src={ALERT_SUCCESS} alt="" />
          <Title>Thank you</Title>
          <Description2>
            <p>
              We will contact you in the next 24 hours to confirm your prize decision.
            </p>
            <p>
              We will be contacting you from
              {isTesla && <> <Nowrap>(215) 756-6768</Nowrap> and</>}
              {' '}admin@lochnesslabs.io
            </p>
          </Description2>
        </Content>
      </Form>
    </>
  );
};

export default McClaimFinish