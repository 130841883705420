import React from "react";
import styled from "styled-components";

const SmallText = styled.p`
  font-size: 14px;
  justify-content: center;
`;

const BoldedSmallText = styled.p`
  font-size: 26px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  margin: 0 0 10px 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: end;
`;

const BuyButton = styled.a`
  width: 206px;
  height: 57px;
  border-radius: 50px 50px 50px 50px;
  border: 2px solid #000;
  color: #000;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`;

const SectionItemsWrapper = styled.div`
  overflow-x: hidden;
  display: grid;
  background-color: #fff;
  color: #000;
  padding: 40px 4%;
  grid-template-columns: auto auto;
  gap: 30px;

  @media screen and (max-width: 900px) {
    text-align: center;
    grid-template-columns: 1fr;

    & ${ButtonWrapper} {
      justify-content: center;
    }
  }
  @media screen and (max-width: 600px) {
    & ${ButtonWrapper} {
      flex-direction: column;
    }
  }
`;


export default function SectionBuy() {
  return (
    <div>
      <SectionItemsWrapper>
        <div>
          <BoldedSmallText>BUY A FREAK</BoldedSmallText>
          <SmallText>
            The initial sale has sold out. To get your Jungle Freak, check out the
            collection <b>on OpenSea or LooksRare.</b>
          </SmallText>
        </div>
        <ButtonWrapper>
          <BuyButton href="https://looksrare.org/collections/0x7E6Bc952d4b4bD814853301bEe48E99891424de0">
            BUY ON LOOKSRARE
          </BuyButton>
          <BuyButton href="https://opensea.io/collection/jungle-freaks-genesis">
            BUY ON OPENSEA
          </BuyButton>
        </ButtonWrapper>
      </SectionItemsWrapper>
    </div>
  );
}
