import LOGO from "../static/images/JF2.png";
import styled from "styled-components";

import { Title } from "./Policy";
import { Subtitle } from "./Policy";
import { Text } from "./Policy";
import { Link } from "react-router-dom";

import McNavbar from "../components/McNavbar";
import Footer from "../components/Footer";

const Container = styled.div`
  background: black;
  color: white;
  & > ol {
    color: white;
  }
  li {
    text-align: left;
    margin-bottom: 1em;
  }
`;

const Inner = styled.div`
  padding: 5% 8%;
`;

const TeslaTerms = () => {
  return (
    <Container>
      <McNavbar />
      <Inner>
        <Title>
          JUNGLE FREAKS MOTOR CLUB - TESLA GIVEAWAY TERMS AND CONDITIONS
        </Title>
        <Text>OFFICIAL RULES</Text>
        <Text>Sponsored by Lochness Labs LLC</Text>
        <Text>
          NO PURCHASE NECESSARY TO ENTER TO WIN. HOWEVER, THERE ARE FEES
          ASSOCIATED WITH SOME ENTRY METHODS. VOID WHERE PROHIBITED OR
          RESTRICTED BY LAW.
        </Text>
        <Text>MAKING A PURCHASE DOES NOT INCREASE YOUR CHANCES OF WINNING</Text>
        <Text>
          The <b>Jungle Freaks Motor Club – Tesla Giveaway</b> (the{" "}
          <b>“Giveaway”</b>) begins on March 17, 2022 at 12:00 pm EST and ends
          on March 21, 2022 at 12:00 pm EST (the <b>“Promotion Period”</b>). The
          odds of winning the Giveaway depend upon the number of JFMC Metaverse
          Vehicle Passes minted and the number of written-in entries received
          during the Promotion Period.
        </Text>
        <Text>
          <b>1. Agreement to Official Rules.</b> This Giveaway is promoted and
          sponsored by Lochness Labs LLC (the “Sponsor”). By participating in
          the Giveaway, each participant (“Participant” defined below)
          unconditionally accepts and agrees to abide by and comply with these
          Official Rules (the “Official Rules”) in their entirety and the
          decisions of the Sponsor, which shall be final and binding in all
          respects.
        </Text>
        <Text>
          <b>2. Eligibility.</b> The Giveaway is open to anyone who is at least
          eighteen (18) years of age or older at the time of entry (the
          “Participant”). The Participant must have an active Ethereum
          cryptocurrency wallet, an active email account, and Internet access
          prior to the beginning of the Promotion Period. An Ethereum
          cryptocurrency wallet may be created without any cost by a
          participant. This Giveaway is subject to all applicable federal,
          state, and local laws and regulations and is void where prohibited.
          While a Participant does not have to live in the United States to
          enter, only legal residents of the fifty (50) states of the United
          States, including the District of Columbia and Puerto Rico, may claim
          the Tesla Model 3 prize. All other participants must accept the cash
          value of the prize, valued on March 21, 2022, delivered in Ethereum to
          the Winner’s Ethereum cryptocurrency wallet.
        </Text>
        <Text>
          Sponsor and their immediate family members, household members, parent
          companies, subsidiaries, affiliates, distributors, retailers, sales
          representatives, participating promotional partners, advertising and
          promotion agencies, webmasters, any company involved in the creation,
          design, execution, production, or fulfillment of the Giveaway and each
          of their respective officers, directors, and employees are ineligible
          to enter the Giveaway or win a prize. Household members and immediate
          family members of such individuals are also not eligible to enter or
          win. “Household members” means those people who share the same
          residence. “Immediate family members” includes but is not limited to a
          spouse, children, parents, siblings, grandparents, stepfamily, and
          their respective spouses. This Giveaway is subject to all applicable
          federal, state, and local laws and regulations.
        </Text>
        <Text>
          Eligibility is at the exclusive discretion of the Sponsor, and the
          Sponsor shall be entitled to reject any Participant for any reason or
          no reason at all without having to provide an explanation. Sponsor
          reserves the right, at its sole discretion, to cancel, suspend, or
          terminate the Giveaway for any reason and without prior notice to any
          Participant.
        </Text>
        <Text>
          <b>4. Entry Period.</b> The Giveaway begins on Thursday, March 17,
          2022 at 12:00 pm EST and ends on Sunday, March 21, 2022 at 12:00 pm
          EST (the “Promotion Period”). The official timekeeping clock shall be
          the Sponsors’ clock. All entries must be received during the Promotion
          Period to be eligible to win a prize. Entries will not be accepted
          following the expiration of the Promotion Period on Sunday, March 21,
          2022 at 12:00 pm EST.
        </Text>
        <Text>
          <b> 5. How to Enter. </b>No purchase necessary to enter. Ethereum gas
          fees and standard rates, if any, charged by the Participants’ Internet
          service or mobile provider will apply. During the Promotion Period,
          Participants may enter the Giveaway by:
        </Text>
        <ol>
          <li>
            <b>
              Minting a JFMC Metaverse Vehicle Pass during the Promotion Period.
            </b>
            Participants may enter the Giveaway during the Promotion Period
            online by minting a JFMC Metaverse Vehicle Pass and visiting the
            Sponsor’s website to complete a short Entry Survey.
          </li>
          <li>
            <b>Entry by Mail.</b> To enter by mail without obligation,
            Participant must mail their full name, address, city, state, zip
            code, e-mail address, telephone number, date of birth, and their
            Ethereum cryptocurrency wallet address to: 406 Glenrock Road, Ridley
            Park, Pennsylvania, 19078. Mail-in entries must be postmarked by
            March 17, 2022 and actually received by Sponsor by March 19, 2022 at
            11:59pm EST. For each mail-in entry received as stated above, each
            mail in participant will receive a maximum of one (1) entry into the
            Giveaway. Additional mail-in entries from the same household or
            entries using the same Ethereum cryptocurrency wallet will not be
            eligible to receive more than one (1) entry. Proof of mailing does
            not constitute proof of delivery.
          </li>
        </ol>
        <Text>
          By entering the Giveaway, Participant: (i) agrees to be bound by these
          Official Rules and by the interpretation of these Official Rules by
          the Sponsor and by the decisions of the Sponsor, which are final and
          binding in all respects; (ii) acknowledges compliance with these
          Official Rules; and (iii) agrees to comply with any and all applicable
          federal, state and local laws, rules and regulations. Participation in
          the Giveaway constitutes Participant’s full and unconditional
          acceptance of these Official Rules.
        </Text>
        <Text>
          Participants are required to provide complete and truthful
          information. The Sponsor is not responsible for entries that are:
          lost, late, incomplete, illegible, damaged, incorrect, corrupted,
          false, misdirected, deceptive, or otherwise not in compliance with the
          Official Rules. The Sponsor is not responsible for technical issues
          with the entry process and/or unauthorized or fraudulent interference
          with the entry process. The Sponsor may void entries and disqualify
          Participants at the Sponsor’s sole and absolute discretion. Entries
          submitted by individuals who do not meet the eligibility requirements
          (including all requirements with respect to age and residence) are
          void and the Sponsor will disqualify those individuals. The use of any
          robotic, automatic, programmed, or similar fraudulent entry method or
          entering more than the number of times permitted will be void and
          result in disqualification. Participants may not enter more times than
          indicated by using multiple email addresses, identities, or
          cryptocurrency wallets. All entries submitted in compliance with these
          Official Rules and not disqualified or void are considered “Eligible
          Entries.” All entries are the property of the Sponsor.
        </Text>
        <Text>
          <b>6.  Prize.</b> The Winner (defined below) of the Giveaway will
          receive one (1) Tesla Model 3 (the <b>“Prize”</b>) with an approximate
          retail value of $44,990 USD. Participants who are ineligible to
          receive the Tesla Model 3 prize, must accept the cash value of the
          Prize, as valued on March 21, 2022 according to the coinmarketcap.com
          ETH closing price, delivered in Ethereum to the Winner’s verified
          Ethereum cryptocurrency wallet. No more than one (1) Prize will be
          distributed in the Giveaway. The odds of winning depend on the number
          of entries received.
        </Text>
        <Text>
          If for any reason the Prize, or any part of a Prize, is unavailable,
          the Sponsor reserves the right to modify the Giveaway at its sole
          discretion and award a substitute prize, or portion of the prize, of
          comparable or greater value as set forth in these Official Rules. No
          substitution, transfer, assignment, or cash equivalent of the Prize,
          or any portion thereof, is permitted by the Winner. The Sponsor shall
          have no responsibility or obligation to a Winner who is unable or
          unavailable to, or who does not for any reason, accept or utilize the
          Prize. In the event a potential Winner cannot accept the Prize, an
          alternate Winner will be randomly drawn from all remaining Eligible
          Entries. All costs and expenses not specifically listed above as part
          of the Prize are solely Winner’s responsibility. The Winner accepts
          the Prize “as is.” Participants acknowledge that the Sponsor has
          neither made nor are in any manner responsible or liable for any
          warranty, representation, or guarantee, express or implied, in fact or
          in law, relative to the Prize, including any implied warranty of
          merchantability or fitness for a particular purpose. Any prize
          pictured in advertising and other Giveaway materials is for
          illustrative purposes only. The Tesla Model 3 may take significant
          time to be ordered and delivered to the Winner, or may be on
          backorder. The Winner will be required to execute certain additional
          documents, including a release acceptable to Sponsor, in order to
          claim and accept delivery of the Prize.
        </Text>
        <Text>
          The value of the Prize may be taxable to the Winner as income. All
          federal, state, and local tax liability, and any other costs and
          expenses associated with acceptance or use of the Prize not
          specifically provided for in these Official Rules are solely the
          Winner’s responsibility (regardless of whether the Prize, in whole or
          in part, is used).
        </Text>
        <Text>
          <b>7.  Winner Selection and Notification.</b>  The winner of the
          Giveaway is the Participant who owns the JFMC Metaverse Vehicle Pass
          that carries the “Tesla Winner” trait (the <b>“Winner”</b>).
          Participants will know whether their JFMC Metaverse Vehicle Pass
          carries the “Tesla Winner” trait (the <b>“Winning Trait”</b>) once the
          JFMC Metaverse Vehicle Pass is revealed on March 25, 2022 at 1:00 pm
          EDT within the sole and exclusive discretion of the Sponsor.
        </Text>
        <Text>
          The Prize will be awarded to the owner of the cryptocurrency wallet
          address holding a JFMC Metaverse Vehicle Pass carrying the Winning
          Trait. The JFMC Metaverse Vehicle Pass carrying the Winning Trait will
          be identified within seven (7) days (or such other time specified by
          the Sponsor) following the reveal of the Winning Trait or the end of
          the Promotion Period by Twitter or such other method specified by the
          Sponsor. However, the Sponsor may not have a method of direct
          communication with the Winner, and the upon reveal, the Winner holding
          the Winner Trait will have an affirmative duty to come forth and
          present themselves to the Sponsor through Discord, Twitter or other
          communication with Sponsor’s verified communication channels. The
          owner of the cryptocurrency wallet is the individual with the
          necessary passwords and seed phrase to access the wallet, and Sponsor
          may require additional confirmation details to confirm ability to
          access a wallet holding the Winning Trait.
        </Text>
        <Text>
          <b>8. Winner Verification.</b> . The Winner will be required to
          confirm their identity and eligibility including, without limitation,
          proof of age, residence, and identity with a government-issued photo
          identification to claim their Prize. Confirmation acceptable to
          Sponsor must be received within thirty (30) days after being
          notified of being the Winner (the “Verification Window”). If Winner
          does not come forward and provide documentation acceptable to Sponsor
          within the Verification Window, then Winner will forfeit the Prize and
          Sponsor will select an alternate Winner.
        </Text>
        <Text>
          The Winner is not official and shall not be confirmed as the Winner
          until they have completed, signed, and returned documentation required
          by the Sponsor. If the Winner does not complete all forms necessary
          within the time given and/or in the event of non-compliance by the
          Winner, such Winner shall be disqualified and shall forfeit the prize,
          and all privileges otherwise due as a Winner shall be terminated and
          an alternate Winner will be randomly chosen from among all of the
          remaining Eligible Entries.  
        </Text>
        <Text>
          Provided that the Winner is in compliance with the Official Rules and
          after having successfully proven eligibility, a proposed Winner will
          be officially confirmed as the Winner. The Sponsor expressly reserves
          the right to delay the announcement of the Winner for any reason it
          deems necessary. In addition, the Sponsor reserves the right to select
          an alternate Winner in the event that any Winner fails to comply with
          these Official Rules.
        </Text>
        <Text>
          <b>9. Prize Forfeiture.</b> Failure to respond within the applicable
          time period will result in forfeiture of the Prize, and the Sponsor
          shall have the option to award the Prize to an alternate winner. The
          return of the notification of the Prize as undeliverable may also
          result in disqualification and an alternate winner may be selected. If
          the alternate Winner does not comply with these Official Rules or is
          otherwise unable to accept the Prize, the Prize is forfeited and
          another alternate Winner will be selected. This Winner selection and
          notification process shall be continued until a Winner is confirmed.
          The Sponsor is not responsible for digital communications that are
          undeliverable or any other failure beyond its reasonable control. If a
          winner notification is returned as undeliverable, the Prize will be
          forfeited and an alternate Winner will be selected. The Winner of the
          Prize will forfeit the Prize if they are disqualified as not eligible
          to have participated in the Giveaway.
        </Text>
        <Text>
          <b>10. Grants of Rights and Publicity.</b> The Sponsor reserves the
          right to make a public announcement, announcing the Winner on either
          the Sponsor’s website or in any manner including, without limitation,
          social media (e.g., Facebook, Twitter, Discord, Instagram, etc.),
          which they deem appropriate. By entering the Giveaway and/or winning
          the Prize, Participant irrevocably consents to the use of their name,
          image, photograph, likeness, biographical information, entry,
          statements attributed to Participant (if true), and any video footage
          related to the Prize, for all Sponsor marketing, advertising,
          promotional, commercial, and all other publicity purposes in all media
          now or hereafter known, worldwide and in-perpetuity without additional
          compensation. The Winner may be required to execute further documents
          consistent with the above. Please note that the Sponsor may not
          acknowledge or return any entries.
        </Text>
        <Text>
          <b>11.  Liability Release and Indemnity. </b> THE PRIZE IS OFFERED AND
          PROVIDED “AS IS” WITH NO WARRANTY OR GUARANTEE BY THE SPONSOR, EITHER
          EXPRESS OR IMPLIED.  IN NO EVENT WILL THE SPONSOR BE RESPONSIBLE OR
          LIABLE TO THE PARTICIPANT FOR ANY DIRECT, SPECIAL, INCIDENTAL,
          EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES (INCLUDING WITHOUT
          LIMITATION LOSS OF USE, DATA, BUSINESS, OR PROFITS) ARISING OUT OF OR
          IN CONNECTION WITH ANY PARTICIPANT’S PARTICIPATION IN THE GIVEAWAY,
          WHETHER SUCH LIABILITY ARISES FROM ANY CLAIM BASED UPON CONTRACT,
          WARRANTY, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE,
          AND WHETHER OR NOT JUNGLE FREAKS AND/OR OTHER GIVEAWAY ENTITIES HAVE
          BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
        </Text>
        <Text>
          SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF
          LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
          LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
        </Text>
        <Text>
          By entering the Giveaway, Participants waive all right to and agrees
          to indemnify, defend, and hold harmless, the Sponsor from any and all
          liability, for any claims, damages, injuries, losses of any kind
          (including punitive, incidental, and consequential damages), or
          expense (including attorneys’ fees) and to release all rights to bring
          any claim, action or proceeding arising out of, in connection with, or
          relating to, (i) participation in the Giveaway, (ii) without
          limitation, death, or property damage, arising directly or indirectly
          from the acceptance, possession, misuse or use of the Prize, (iii)
          defamation, violation of publicity rights, or invasion of privacy in
          connection with the Giveaway or acceptance and use or the Prize, and
          (iv) copyright infringement, trademark infringement, or any other
          intellectual property-related cause of action arising from or related
          to the Giveaway, the Prize, or the Sponsors’ advertising and marketing
          related to the Giveaway, Participants or the Winner, in all cases,
          including but not limited to:  (a) unauthorized human intervention in
          the Giveaway; (b) technical errors related to computers, servers,
          providers, or telephone, or network lines; (c) printing errors; (d)
          lost, late, postage-due, misdirected or undeliverable mail; (e) errors
          in the administration of the Giveaway or the processing of entries; or
          (f) injury or damage to persons or property (including to any computer
          or mobile device systems resulting from participation in or accessing
          or downloading information in connection with the Giveaway), which may
          be caused, directly or indirectly, in whole or in part, from
          Participant’s participation in the Giveaway or receipt or use of the
          Prize. Participant assumes all liability for any injury or damage
          caused or claimed to be caused, by participation in the Giveaway, or
          the acceptance, receipt, or use of the Prize or any Prize component.
          Participant further agrees that in any cause of action, the Sponsors’
          liability will be limited to the value of the Prize.
        </Text>
        <Text>
          The Sponsor assumes no responsibility for and will disqualify entries
          that are: stolen, late, lost, illegible, incomplete, invalid,
          unintelligible, altered, tampered with, unauthorized, fraudulent,
          damaged, destroyed, delayed, misdirected, not delivered, not received,
          or that have incorrect or inaccurate entry information, whether caused
          by any of the equipment or programming associated with or utilized in
          the Giveaway, or by any human, mechanical or electronic error that may
          occur in the processing of the entries in the Giveaway, or other
          errors appearing within the Official Rules or in the Giveaway-related
          advertisements. The Sponsor assumes no responsibility for any
          typographical or other error in the printing of the offer,
          administration of the Giveaway, errors in processing entries,
          identifying the Winner, in the announcement of the Prize and Winner,
          the delivery of the Prize, any problems or technical malfunction of
          any telephone network or lines, computer systems, online systems,
          servers or providers, computer equipment, software, failure of any
          e-mail or players on account of technical problems or traffic
          congestion on the Internet or any web site including junglefreaks.io,
          or any combination thereof, including, without limitation, any injury
          or damage to Participant’s or any other person’s computer
          system/software related to or resulting from participation in,
          uploading any materials or downloading any materials in the Giveaway.
          Use of any device to automate or subvert entry is prohibited and any
          entries received by such means will be void. The Sponsor reserves the
          right in its sole discretion to disqualify any person it suspects or
          finds (i) to have tampered with the entry process or the operation of
          the Giveaway; (ii) to be acting in a disruptive manner, or with the
          intent to annoy, abuse, threaten or harass any other person;  (iii) to
          display behavior that will bring such Winner or the Sponsor into
          disgrace; (iv) to have provided inaccurate information on any legal
          documents submitted in connection with the Giveaway; or (v) to be
          acting in violation of these Official Rules. ANY VIOLATION OF THESE
          OFFICIAL RULES BY A WINNER WILL RESULT IN SUCH WINNER’S
          DISQUALIFICATION AS A WINNER OF THE GIVEAWAY AND ALL PRIVILEGES AS A
          WINNER WILL BE IMMEDIATELY TERMINATED.
        </Text>
        <Text>
          <b>12.  General Terms.</b> The Sponsor is not responsible if the
          Giveaway cannot take place, or if the Prize cannot be awarded due to
          delays, interruptions, or failures due to acts of God, war, natural
          disasters, weather, acts or threats of terrorism, strikes, lockouts,
          labor disputes, work stoppages, fire, acts of government, or other
          events outside of the reasonable control of the Sponsor. If, for any
          reason the Giveaway is not capable of running as planned, including,
          without limitation, due to infection by computer virus, bugs,
          tampering, unauthorized intervention, fraud, technical failures, or
          any other causes beyond the reasonable control of the Sponsor, which,
          in the Sponsors’ sole determination, corrupts or affects the
          administration, security, fairness, integrity or proper conduct of the
          Giveaway, the Sponsor reserves the right, in its sole discretion, to
          cancel, terminate, modify, or suspend the Giveaway or any part of the
          Giveaway. If the Giveaway or any part of the Giveaway is terminated or
          modified prior to the closing of the Promotion Period, notice will be
          posted on Sponsor’s Twitter and Discord. The Sponsor reserves the
          right to modify and amend these Official Rules from time to time
          during the Promotion Period for clarification purposes.
        </Text>
        <Text>
          ANY ATTEMPT BY ANY PERSON TO DELIBERATELY DAMAGE OR UNDERMINE THE
          LEGITIMATE OPERATION OF THE GIVEAWAY MAY BE IN VIOLATION OF CRIMINAL
          AND CIVIL LAW. SHOULD SUCH AN ATTEMPT BE MADE, THE SPONSOR RESERVES
          THE RIGHT TO SEEK REMEDIES AND DAMAGES (INCLUDING ATTORNEYS’ FEES)
          FROM ANY SUCH PERSON TO THE FULLEST EXTENT PERMITTED BY LA
        </Text>
        <Text>
          The invalidity or unenforceability of any provision of these Official
          Rules will not affect the validity or enforceability of any other
          provision. In the event that any provision of the Official Rules is
          determined to be invalid or otherwise unenforceable or illegal, the
          other provisions will remain in effect and will be construed in
          accordance with their terms as if the invalid or illegal provision
          were not contained herein. The Sponsor's failure to enforce any term
          of these Official Rules will not constitute a waiver of that term or
          any other provision of these Official Rules. Participant agrees to
          waive any rights to claim ambiguity of these Official Rules. Headings
          are solely for convenience of reference and will not be deemed to
          affect in any manner the meaning or intent of these Official Rules or
          any provision hereof. In the event there is a discrepancy or
          inconsistency between disclosures or other statements contained in the
          Giveaway-related materials, privacy policy, or terms of use on a
          website and/or the terms and conditions of these Official Rules, the
          Official Rules shall prevail, govern and control and the discrepancy
          will be resolved in the Sponsor’s sole and absolute discretion.
        </Text>
        <Text>
          <b>13.  Privacy.</b> All information submitted by Participants and
          collected by the Sponsor in connection with the Participant’s entry
          will be subject to and will be treated in a manner consistent with the
          Sponsor’s Privacy Notice available at: https://junglefreaks.io. By
          participating in the Giveaway, Participant hereby agrees that the
          Sponsor may collect and use his or her personal information submitted
          with the entry, and acknowledges that he or she has read and accepted
          the Sponsor’s Privacy Notice. Among other things, the information
          Participants provide may be used for sending Participant company
          updates and announcements about the Sponsor’s products and/or
          services.
        </Text>
        <Text>
          <b>11.  Binding Arbitration.</b>  Except where prohibited by law,
          Participant agrees that: (1) any dispute, controversy or claim arising
          out of or relating to the Giveaway or the Prize shall be resolved
          individually, without resort to any form of class action; (2) any
          dispute, controversy or claim arising out of or relating to the
          Giveaway or the Prize shall be resolved by binding arbitration
          administered by the American Arbitration Association in accordance
          with the Commercial Arbitration Rules of the AAA then in effect; and
          (3) any arbitration shall be heard by one arbitrator to be selected in
          accordance with the Commercial Arbitration Rules, in New York and the
          arbitration proceedings shall be conducted in English; (4) unless both
          Participant and the Sponsor agree in writing, the arbitrator may not
          consolidate more than one person’s claims, and may not otherwise
          preside over any form of class or representative proceeding; (5)
          judgment upon any award rendered may be entered in any court having
          jurisdiction thereof; (6) any award or judgment shall be subject to
          all limitations and releases set forth in these Official Rules and be
          limited to actual out of pocket damages, and shall not, in any event,
          include any punitive, exemplary, consequential or incidental damages,
          attorney’s fees or costs of bringing a claim, or any injunctive or
          other equitable relief; and (7) you agree to first commence a formal
          dispute proceeding by completing and submitting an Initial Dispute
          Notice which can be found here. The Covered Party(ies) named in your
          Initial Dispute Notice (collectively, the “Named Parties”) may choose
          to provide you with a final written settlement offer after receiving
          your Initial Dispute Notice (“Final Settlement Offer”). If the
          applicable Named Party(ies) provide(s) you with a Final Settlement
          Offer and you do not accept it, or such Named Party(ies) cannot
          otherwise satisfactorily resolve your dispute and you wish to proceed,
          you must submit your dispute for resolution by arbitration before the
          AAA, in your county of residence, by filing a separate Demand for
          Arbitration, which is available here. For claims of Ten Thousand
          Dollars ($10,000.00) or less, you can choose whether the arbitration
          proceeds in person, by telephone, or based only on submissions. If the
          arbitrator awards you relief that is greater than the applicable Final
          Settlement Offer, then the Named Party(ies) will pay all filing,
          administration, and arbitrator fees associated with the arbitration
          and, if you retained an attorney to represent you in connection with
          the arbitration, the Named Party(ies) will reimburse any reasonable
          attorneys' fees that your attorney accrued for investigating,
          preparing and pursuing the claim in arbitration. Any award rendered
          shall be final and conclusive to the parties and a judgment thereon
          may be entered in any court of competent jurisdiction. Although the
          Named Party(ies) may have a right to an award of attorneys' fees and
          expenses if Named Party(ies) prevail(s) in arbitration, the Named
          Party(ies) will not seek such an award from you unless the arbitrator
          determines that your claim was frivolous.
        </Text>
        <Text>
          To the extent permitted by law, you agree that you will not bring,
          join or participate in any class action lawsuit as to any claim,
          dispute, or controversy that you may have against any of the Covered
          Parties. You agree to the entry of injunctive relief to stop such a
          lawsuit or to remove you as a participant in the suit. You agree to
          pay the attorney's fees and court costs that any Covered Party incurs
          in seeking such relief. This provision preventing you from bringing,
          joining, or participating in class action lawsuits: (i) does not
          constitute a waiver of any of your rights or remedies to pursue a
          claim individually and not as a class action in binding arbitration as
          provided above; and (ii) is an independent agreement. You may opt out
          of these dispute resolution provisions by providing written notice of
          your decision within thirty (30) days of the date that you first enter
          the Promotion.
        </Text>
        <Text>
          SOME JURISDICTIONS DO NOT ALLOW THE LIMITATIONS OR EXCLUSION OF
          LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE MAY
          NOT APPLY TO YOU.
        </Text>
        <Text>
          <b>12.  Governing Law. </b> All federal, state, and local laws and
          regulations apply. Void where prohibited by law. All issues and
          questions concerning the construction, validity, interpretation, and
          enforceability of these Official Rules, or the rights and obligations
          of the Participant and the Sponsor in connection with the Giveaway,
          shall be governed by and construed in accordance with, the laws of New
          York without giving effect to any choice of law or conflict of laws
          rules.
        </Text>
        <Text>
          <b>13.  Accessibility of Official Rules and Winner Announcement.</b> A
          copy of the Official Rules is available via junglefreaks.io. Where
          required by law, the Sponsor will provide the name of Winner of a
          Giveaway to those who request it. Requests for the announced winner
          shall be emailed to the Sponsor at junglefreaksnft@gmail.com within
          three (3) months of the end of the Promotion Period.
        </Text>
        <Text>
          <b>14.  Administered by: </b>The Jungle Freaks Motor Club – Tesla
          Giveaway is administered by SCA Promotions, located at 3030 Lyndon B.
          Johnson Fwy, #300, Dallas, Texas 75234.
        </Text>
      </Inner>
      <Footer />
    </Container>
  );
};

export default TeslaTerms;
