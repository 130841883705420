import React from "react";
import styled from "styled-components";
import BG from "../static/images/banner2.png";
import CENTS from "../static/images/jungle-cents.png";
import Heading from "./Heading";
import Button, { ButtonAction } from "./Button";

const ColumnImg = styled.div`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: auto;
  & img {
    width: 80%;
  }
`

const Overlay = styled.div`
  background: rgba(0,0,0,0.85);
  padding: 70px 8% 80px 8%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 30px;
`;

const BuyButton = styled.a`
  width: 206px;
  height: 57px;
  border-radius: 50px 50px 50px 50px;
  border: 2px solid #f59e0c;
  background-color: #f59e0c;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`;

const Container = styled.div`
  background: transparent url('${BG}') no-repeat;
  color: #fff;
  background-size: 100% 100%;
  
  & p {
    font-size: 16px;
    line-height: 24px
  }
  & p a {
    color: #0292e0;
  }
  & ${Button} {
    margin-top: 20px;
  }

  @media screen and (max-width: 900px) {
    background-size: auto 100%;
    &, & ${Heading} {
      text-align: center;
      padding-top: 0;
    }
    & ${Overlay} {
      grid-template-columns: 1fr;
    }
    & ${ColumnImg} {
      width: 70%;
      grid-row: 1;
    }
  }
`;

export default function SectionStakeNow() {
  return (
    <Container id="jungle">
      <Overlay>
        <div>
          <Heading style={{ marginLeft: 0 }}>$JUNGLE</Heading>
          <p>
            The $JUNGLE token is the token of the Jungle Freaks Ecosystem.
          </p>
          <p>
            It is the life blood of all things in the Jungle Freak Community.
            The $JUNGLE token will be at the center of transacting and benefiting from new functionality,
            as more utility, and functionality is rolled out to deliver value to the community.
          </p>
          <p>
            For our upcoming collection launches the $JUNGLE tokens will be giving access
            to exclusive perks including NFTs and discounts.
          </p>
          <p>
            The ONLY way to earn $JUNGLE is by owning and staking
            a <a href="https://opensea.io/collection/jungle-freaks-genesis">Genesis Jungle Freaks Collection</a> NFT
            and/or <a href="https://opensea.io/collection/jf-legendary-vault">Jungle Freaks Legendary Vault</a> NFT.
          </p>
          <p>
            $JUNGLE will be rewarded daily.
          </p>
          <p>
            For more details refer to our <a href="https://medium.com/@junglefreaksnft/jungle-token-overview-b64ecec5aa5e">blog post</a> on it.
          </p>
          <BuyButton href="https://dash.junglefreaks.io/">
            STAKE NOW
          </BuyButton>
        </div>
        <ColumnImg>
          <img src={CENTS} alt="" />
        </ColumnImg>
      </Overlay>
    </Container >
  );
}
