import React from "react";
import styled from "styled-components";
import ROADMAP from "../static/images/roadmap.png";
import CHECKED from "../static/images//icons/checked.svg";
import Heading from "./Heading";
import LOGO from "../static/images/logo.svg";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2.2fr 0.8fr;
  gap: 10px;
  padding: 70px 8% 0px 8%;
  align-items: center;
`;

const GridList = styled.div`
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  gap: 10px;
  grid-auto-flow: column;
`;

const ItemList = styled.div`
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 12px;
  padding-bottom: 84px;

  & img, & span {
    width: 16px;
  }
  & span {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #F59E0C;
  }
`;

const MainImg = styled.img`
  width: 100%;
`;

const LogoWrap = styled.div`
  padding-bottom: 84px;
  & img {
    width: 90%;
  }
`;

const SectionItemsWrapper = styled.div`
  background: #000;
  color: #fff;
  padding-top: 10px;
  & p {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    max-width: 700px;
    margin: 5px auto 60px auto;
  }

  @media screen and (max-width: 1100px) {
    & ${Grid} {
      grid-template-columns: 1fr;
      & > div:nth-child(2) {
        display: none;
      }
    }
  }
  @media screen and (max-width: 900px) {
    & ${GridList} {
      grid-template-rows: repeat(8, 1fr);
    }
  }
`;

export default function SectionRoadmap() {
  return (
    <SectionItemsWrapper id="roadmap">
      <Heading align="center">THE ROADMAP</Heading>
      <p>
        The year is 2077, humanity is gone and the zombies have overrun the cities and seized
        a lot of the military stockpiles. However, they didn’t count on the genetically enhanced
        ferocity and cunning traits of the gorilla regime. The Gorilla oasis stronghold is under
        attack but they will never surrender.
      </p>

      <MainImg src={ROADMAP} alt="" />
      <Grid>
        <GridList>
          <ItemList>
            <img src={CHECKED} alt="" />
            <div><b>FREAKONOMY</b> - $JUNGLE token &amp; staking</div>
          </ItemList>
          <ItemList>
            <img src={CHECKED} alt="" />
            <div><b>JF DAO</b> - Voting mechanism for community</div>
          </ItemList>
          <ItemList>
            <span>3.</span>
            <div><b>THE METAVERSE (2022)</b> - The jungles online presense starts</div>
          </ItemList>
          <ItemList>
            <span>4.</span>
            <b>JUNGLE FREAKS MOTOR CLVB</b>
          </ItemList>

          <ItemList>
            <span>5.</span>
            <b>FUTURE GENERATIVE PROJECTS</b>
          </ItemList>
          <ItemList>
            <span>6.</span>
            <b>_.-_A_ _A-.!_ - =_._..</b>
          </ItemList>
          <ItemList>
            <span>7.</span>
            <div><b>REAL LIFE FREAKS</b> - Events (ongoing)</div>
          </ItemList>
          <ItemList>
            <span>8.</span>
            <div><b>FREAK LABS</b> - *Undisclosed &amp; top secret JF plans</div>
          </ItemList>
        </GridList>
        <LogoWrap>
          <img src={LOGO} alt="" />
        </LogoWrap>
      </Grid>
    </SectionItemsWrapper>
  );
}
