import "./App.css";
import HomePage from "./Screens/Homepage";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Dashboard from "./Screens/DashBoard";
import Terms from "./Screens/Terms";
import Policy from "./Screens/Policy";
import VehicleForChange from "./Screens/VehicleForChange";
import MotorClub from "./Screens/MotorClub";
import MotorClubClaim from "./Screens/MotorClubClaim";
import MotorClubMint from "./Screens/MotorClubMint";
import TeslaTerms from "./Screens/TeslaTerms";

function App() {
  return (
    <>
      <Router>
        <div className="App"></div>
        <div className="content">
          <Switch>
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route exact path="/dashboard">
              <Dashboard />
            </Route>
            <Route exact path="/terms">
              <Terms />
            </Route>
            <Route exact path="/policy">
              <Policy />
            </Route>
            <Route exact path="/vehicle-for-change">
              <VehicleForChange />
            </Route>
            <Route exact path="/jfmc">
              <MotorClub />
            </Route>
            <Route exact path="/jfmc-claim">
              <MotorClubClaim />
            </Route>
            <Route exact path="/jfmc-mint">
              <MotorClubMint />
            </Route>
            <Route exact path="/tesla-giveaway-terms">
              <TeslaTerms />
            </Route>
          </Switch>
        </div>
      </Router>
    </>
  );
}

export default App;
