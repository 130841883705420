import axios from "axios";

export default class Api {
  static api = process.env.REACT_APP_API_URI;

  static async get(url) {
    const res = await axios.get(`${this.api}/${url}`);
    return res.data;
  }

  static async post(url, params) {
    const res = await axios.post(`${this.api}/${url}`, params);
    return res.data;
  }

  static async saleInfo(walletAddress) {
    return this.get(`sale-info/${walletAddress}`);
  }

  static async whitelist(walletAddress) {
    return this.get(`whitelist/${walletAddress}`);
  }

  static async sign(data) {
    return this.post('sign', data);
  }

  static async checkClaim(data) {
    return this.post('check-claim', data);
  }

  static async submitClaim(data) {
    return this.post('submit-claim', data);
  }
}
