import VIDEO_THUMB from "../static/images/jfmc-video-thumb-2.png";
import GAME_COVER from "../static/images/jfmc-game-cover.png";
import GAME_COVER_SM from "../static/images/jfmc-game-cover-sm.png";
import styled from "styled-components";
import { useState } from "react";
import McModal from "./McModal";

const VideoList = styled.ul`
  font-weight: 700;
  font-size: 20px;
  line-height: 40px;
  padding-left: 22px;
`;

const EmptyColumn = styled.div`
  flex: 1;
  width: 50%
`;

const VideoColumn = styled.div`
  flex: 1;
`;

const VideoThumbImg = styled.img`
  max-width: 100%;
`;

const VideoTitle = styled.div`
  font-size: 35px;
  line-height: 55px;
  padding: 10px 0;

  & b {
    display: block;
    font-size: 45px;
    font-weight: 800;
  }
`;

const VideoTitleMobile = styled.div`
  font-size: 25px;
  align-items: baseline;
  justify-content: space-between;
  display: none;
  margin: -105px 4% 0 4%;
`;

const BgCover = styled.div`
  background-image: url(${GAME_COVER});
  background-size: auto 100%;
  background-position: calc(10vw - 255px) 0;
  background-position: min(0px, 10vw - 255px) 0;
  background-repeat: no-repeat;
  display: flex;
  padding: 100px 4%;
  gap: 20px;
`;

const Container = styled.div`
  background: #c7c5c4;
  color: #121619;

  @media screen and (max-width: 900px) {
    background: #000;
    color: #fff;

    & ${BgCover} {
      background-image: none;
      padding: 100px 0;
    }

    & ${VideoThumbImg} {
      width: 100%;
    }

    & ${VideoTitleMobile} {
      display: flex;
    }

    & ${VideoList} {
      margin-left: 4%;
      font-size: 16px;
    }

    & ${VideoTitle} {
      display: none;
    }

    & ${EmptyColumn} {
      display: none;
    }
  }
`;

const McGameHighlight = () => {
  const [mcModalVisible, setMcModalVisible] = useState(false);

  return (
    <Container>
      <BgCover>
        <EmptyColumn />
        <VideoColumn>
          <VideoTitle>
            <b>JUNGLE FREAKS MOTOR CLUB</b>
            P2E RACING GAME
          </VideoTitle>
          <VideoThumbImg src={VIDEO_THUMB} onClick={() => setMcModalVisible(true)} alt="" />
          <VideoTitleMobile>
            <div>
              <b>JFMC </b>
              P2E RACING GAME
            </div>
            <img src={GAME_COVER_SM} alt="" />
          </VideoTitleMobile>
          <VideoList>
            <li>P2E in Netvrk Metaverse</li>
            <li>P2E in NFT Worlds Metaverse </li>
            <li>And more!</li>
          </VideoList>
        </VideoColumn>
      </BgCover>
      {mcModalVisible && (
        <McModal onClose={() => setMcModalVisible(false)} videoId="20761c1e849ebc39fa4b5ba3cb47727b" />
      )}
    </Container>
  );
};

export default McGameHighlight;
