import styled from "styled-components";

const GridDates = styled.div`
  position: relative;
  padding: 0 50px 90px 50px;
  display: grid;
  gap: 9px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: "header content1 content2 content3";
  & > div {
    font-weight: bold;
    white-space: nowrap;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    &:nth-child(1) {
      text-align: left;
      grid-area: header;
    }
    &:nth-child(2) {
      grid-area: content1;
    }
    &:nth-child(3) {
      grid-area: content2;
    }
    &:nth-child(4) {
      grid-area: content3;
    }
    & > div {
      font-weight: 400;
      font-size: 20px;
    }
  }
  @media screen and (max-width: 900px) {
    padding-right: 5%;
    padding-left: 5%;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0;
    grid-template-areas:
      "header header header"
      "content1 content2 content3";

    & > div {
      font-size: 20px;
      line-height: 24px;
      & > div {
        font-size: 14px;
      }
    }
    & > div:nth-child(1) {
      margin-bottom: 40px;
    }
    & > div:nth-child(2) {
      width: 104px;
    }
    & > div:nth-child(4) {
      width: 104px;
      justify-self: end;
    }
  }
`;

const McHeroDates = () => (
  <GridDates>
    <div>
      JUNGLE FREAKS
      <div>MOTOR CLUB COLLECTION MINT:</div>
    </div>
    <div>
      03.17
      <div>Allow List</div>
    </div>
    <div>
      03.18 - 03.19
      <div>Holders</div>
    </div>
    <div>
      03.20
      <div>Public</div>
    </div>
  </GridDates>
);

export default McHeroDates;