import React, { useState } from "react";
import styled from "styled-components";
import LOGO from "../static/images/logo-transparent.svg";
import ALERT_CLOSE from "../static/images/icons/alert-close.png";
import { useHistory } from "react-router-dom";

const Centered = styled.div`
  text-align: center;
`;

const FormCloseIcon = styled.img`
  position: absolute;
  cursor: pointer;
  right: 10px;
  top: 10px;
  width: 11px;
  padding: 5px;
  display: none;
  width: 23px;
`;

const LogoContainer = styled(Centered)`
  margin-bottom: 15px;
  display: none;
`;

export const Title = styled.div`
  font-weight: 800;
  font-size: 18px;
`;

export const Description = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
`;

const FormContainer = styled.form`
  background: #F1F1F1;
  border-radius: 10px;
  padding: 62px 60px 22px 60px;
  color: #000;
  max-width: 600px;
  z-index: 1;
  position: relative;

  &.has-header {
    padding-top: 22px;
    & ${LogoContainer}, & ${FormCloseIcon} {
      display: block;
    }
  }
  &, & * {
    box-sizing: border-box;
  }
`;

export const Input = styled.input`
  background: #FFFFFF;
  border: 1px solid #797979;
  border-radius: 3px;
  width: 100%;
  font-size: 16px;
  margin-top: 21px;
  padding: 10px;
`;

export const Button = styled.button`
  background: ${({ outlined }) => outlined ? '#fff' : '#000'};
  color: ${({ outlined }) => outlined ? '#000' : '#fff'};
  border: 1px solid #000;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 18px;
  padding: 7px;
  width: 223px;
  width: min(223px, 95%);
  margin-top: 32px;
`;


export const TwoButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  & ${Button} {
    width: min(223px, 45%);
  }
`;

const Error = styled.div`
  color: #E61E28;
  margin-top: 17px;
`;

const allInputs = [
  { name: 'email', type: 'email', placeholder: 'E-mail Address' },
  { name: 'emailConfirm', type: 'email', placeholder: 'Confirm E-mail Address' },
  { name: 'phoneNumber', type: 'text', placeholder: 'Phone Number' },
  { name: 'phoneNumberConfirm', type: 'text', placeholder: 'Confirm Phone Number' },
];

export const Form = ({ children, hasHeader, ...rest }) => {
  const history = useHistory();
  const onClose = () => history.push('/jfmc');

  return (
    <FormContainer className={hasHeader ? 'has-header' : ''} {...rest}>
      <FormCloseIcon src={ALERT_CLOSE} onClick={onClose} alt="" />
      <LogoContainer>
        <img src={LOGO} alt="" />
      </LogoContainer>
      {children}
    </FormContainer>
  );
};

const McClaimForm = ({ prize, onSubmit, onBack }) => {
  const [form, setForm] = useState({});
  const [error, setError] = useState("");
  const isTesla = prize === 'Tesla';
  const inputs = isTesla ? allInputs : allInputs.slice(0, 2);

  const onChange = (e) => {
    setForm(f => ({
      ...f,
      [e.target.name]: e.target.value
    }));
  };

  const submit = (e) => {
    setError("");
    e.preventDefault();

    if (validateConfirm()) {
      onSubmit(form);
    }
  };

  const validateConfirm = () => {
    const didNotMatch = inputs.find(input => {
      const confirmName = `${input.name}Confirm`;
      const confirmValue = form[confirmName];

      return confirmValue && form[input.name] !== confirmValue;
    });

    if (didNotMatch) {
      setError(`${didNotMatch.placeholder} did not match.`)
      return false;
    }

    if (form.phoneNumber && !/^\+?[0-9 \-\(\)]*$/.test(form.phoneNumber)) {
      setError('Invalid phone number.');
      return false;
    }

    return true;
  }

  return (
    <Form onSubmit={submit}>
      <Description>
        Before we proceed, we need to verify a contact method.
        If prize is not claimed within 30 days, the prize will be forfeited.
      </Description>
      {inputs.map(input => (
        <Input
          required={true}
          key={input.name}
          name={input.name}
          type={input.type}
          value={form.name}
          onChange={onChange}
          placeholder={input.placeholder}
        />
      ))}
      <Centered>
        {error && <Error>{error}</Error>}
      </Centered>
      <TwoButtons>
        <Button type="submit">Submit</Button>
        <Button outlined={true} type="button" onClick={onBack}>Go Back</Button>
      </TwoButtons>
    </Form>
  )
};

export default McClaimForm;