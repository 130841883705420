import React, { useEffect, useState } from "react";
import styled from "styled-components";
import LOGO_TOP_LEFT from "../static/images/logo.svg";
import BANNER from "../static/images/banner4.png";
import COIN from "../static/images/jungle-coin.png";
import LOGO from "../static/images/jfmc-logo-lg.png";
import GWEI_LOGO from "../static/images/gwei-logo.svg";
import ETHERSCAN_LOGO from "../static/images/etherscan-logo-light-circle.svg";
import Button from "./Button";
import useWeb3Connect from "../useWeb3Connect";
import { Link, useHistory } from "react-router-dom";
import JFMCArtefact from "../abi/JungleFreaksMotorClub.json";
import { ethers, utils } from "ethers";
import Api from "../Api";
import AlertMsg from "./AlertMsg";

const LogoTopLeft = styled.img`
  width: 103px;
  position: absolute;
  left: 24px;
  top: 24px;

  @media screen and (max-width: 450px) {
    left: 16px;
    width: 84px;
  }

  @media screen and (max-width: 400px) {
    left: 12px;
  }
`;

const Logo = styled.img`
  width: 286px;
`;

const ProgressBarContainer = styled.div`
  font-weight: 700;
  font-size: 14px;
`;

const ProgressBarMark = styled.div`
  position: abolsute;
  left: 0;
  width: 37%;
  border-right: 1px solid #e61e28;
  height: 100%;
`;

const ProgressBar = styled.div`
  width: 207px;
  height: 4px;
  border: 1px solid #e61e28;
  background: transparent;
  border-radius: 10px;
  margin: 6px auto 10px;
  position: relative;
  &::before {
    position: absolute;
    left: 0;
    top: 0;
    content: " ";
    background: #e61e28;
    height: 100%;
    width: ${({ percent }) => percent}%;
  }
`;

const Title = styled.div`
  font-weight: 800;
  font-size: 35px;
`;

const SelectMintContainer = styled.div``;

const GridHalf = styled.div`
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  ${({ twoLines }) =>
    twoLines &&
    `
    flex-direction: column;
    & > div:nth-child(2) {
      display: none;
    }
  `}
`;

const GridHalfColumn = styled.div`
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};

  &:nth-child(2) {
    width: 1px;
    background-color: #fff;
    height: 15px;
  }
`;

const OutlinedBtn = styled(Button)`
  font-weight: 800;

  &,
  &.inverse.selected,
  &.inverse:hover {
    background: transparent;
    border: 2px solid ${({ color }) => color || "#fff"};
    color: ${({ color }) => color || "#fff"};
  }

  &[disabled] {
    border: 2px solid #8b8b8b !important;
    color: #8b8b8b !important;
    background: transparent !important;
    cursor: default;
  }

  &.selected,
  &:hover,
  &.inverse {
    background: ${({ color }) => color || "#fff"};
    color: ${({ color }) => (!color || color === "#fff" ? "#000" : "#fff")};
  }
`;

const ButtonAction = styled(OutlinedBtn)`
  font-size: 20px;
`;

const MintBtn = styled(OutlinedBtn)`
  height: 50px;
  width: 148px;
`;

const JungleBtns = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const JungleBtn = styled(OutlinedBtn)`
  width: auto;
  height: auto;
  padding: 3px 14px 3px 4px;
  display: flex;
  align-items: center;
  gap: 7px;
  & img {
    width: 35px;
  }
  &:disabled {
    background: #dbe7e730;
  }
`;

const SelectMintBtns = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const JungleTokensContainer = styled.div``;

const JungleTokensTitle = styled.div`
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
`;

const MintContent = styled.div`
  display: flex;
  gap: 50px;
  flex-direction: column;
  min-height: calc(100vh - 115px);
  justify-content: center;
`;

const MintFooter = styled.div`
  display: block;
  align-items: center;
  justify-content: center;
  min-height: 115px;
  font-size: 13px;
`;

const GweiLogoWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px;
  font-weight: 700;
  color: #e71823;
`;

const GweiLogo = styled.img`
  width: 12px;
  margin-right: 4px;
`;

const EtherscanLogoWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  a,
  a:visited {
    color: white;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
`;

const EtherscanLogo = styled.img`
  width: 16px;
  margin-right: 10px;
`;

const DisabledMessage = styled.div`
  font-weight: 800;
  font-size: 18px;
  color: #e61e28;
  text-transform: uppercase;
  margin-top: 30px;
`;

const HeroImage = styled.div`
  background-image: url(${BANNER});
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  text-align: center;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  & * {
    font-family: Montserrat;
  }
`;

const CenteredMsg = styled.div`
  height: 100vh;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const additionaPageInfo = {
  AllowListMint: {
    title: "ALLOW LIST",
    priceTwoLines: true,
  },
  HoldersGuaranteeMint: {
    title: "HOLDER GUARANTEE",
    priceTwoLines: true,
  },
  HoldersMint: {
    title: "HOLDER",
    priceTwoLines: false,
  },
  PublicMint: {
    title: "PUBLIC",
    priceTwoLines: false,
  },
};

function MintLayout({ children }) {
  return (
    <div style={{ backgroundColor: "#000" }}>
      <Link to="/">
        <LogoTopLeft src={LOGO_TOP_LEFT} alt="" />
      </Link>
      <HeroImage>{children}</HeroImage>
    </div>
  );
}

export default function McMintHero() {
  const history = useHistory();
  const { address, signer, web3Modal } = useWeb3Connect();
  const [mintNum, setMintNum] = useState(1);
  const [jungleNum, setJungleNum] = useState(null);
  const [pageInfo, setPageInfo] = useState({});
  const [alert, setAlert] = useState();

  const isPreSale = pageInfo.saleType === "AllowListMint";
  const maxSupply = isPreSale ? 8888 : pageInfo.maxSupply;
  const mintedPercent = (pageInfo.totalMinted / maxSupply) * 100;
  const showPayWithJungle = pageInfo.jungle && !isPreSale;
  const confirmEnabled = mintNum && (jungleNum !== null || !showPayWithJungle);
  const pricePerToken = isPreSale
    ? pageInfo.salePrice
    : pageInfo?.jungleValues?.[jungleNum || 0];
  const priceTotal = pricePerToken * mintNum;
  let disabledMsg = false;

  if (!pageInfo.eligible) {
    disabledMsg = "Your wallet is not elegible for current mint";
  }
  if (pageInfo.saleType === "HoldersGuaranteeMint" && pageInfo.allowance == 0) {
    disabledMsg = "You've exceeded your mint allowance";
  }
  if (pageInfo.saleType === "AllowListMint" && pageInfo.soldOutAllowList) {
    disabledMsg = "Allow List Sold Out";
  }
  if (pageInfo.soldOut) {
    disabledMsg = "Sold Out";
  }
  if (pageInfo.saleType === "None") {
    disabledMsg = "Minting has not started";
  }
  if (pageInfo.status === "PAUSED") {
    disabledMsg = "Minting has been paused";
  }
  if (pageInfo.saleType === "Finished") {
    disabledMsg = "Minting has Finished";
  }

  const addr = process.env.REACT_APP_JFMC_CONTRACT_ADDRESS;
  const add = addr.split("x")[1];
  const a = add.substring(0, 4);
  const b = add.substring(add.length - 4);
  const short = `0x${a}...${b}`;

  const contract = new ethers.Contract(
    process.env.REACT_APP_JFMC_CONTRACT_ADDRESS,
    JFMCArtefact.abi,
    signer
  );

  const constructMessage = ({ jungle, quantity }) => {
    const saleType = pageInfo.saleType;

    // Generate signature
    let msg = "Signature Verification\n\n";
    switch (saleType) {
      case "HoldersGuaranteeMint":
        msg += `Jungle: ${utils.formatEther(jungle).split(".")[0]}\n\n`;
        break;
      case "HoldersMint":
      case "PublicMint":
        msg += `Jungle: ${utils.formatEther(jungle).split(".")[0]}\n`;
        msg += `Quantity: ${quantity}\n\n`;
        break;
      case "AllowListMint":
        msg += `Quantity: ${quantity}\n\n`;
        break;
      default:
        break;
    }
    msg += `security: ${pageInfo.salt}`;

    return msg;
  };

  const txnAccepted = (tx) =>
    setAlert({
      type: "success",
      title: "Transaction Accepted",
      description: (
        <a target="_blank" href={`https://etherscan.io/tx/${tx.hash}`}>
          View Transaction
        </a>
      ),
    });

  const txnFailed = (e) => {
    if (e?.code == 4001) {
      setAlert({
        type: "danger",
        title: "Transaction Cancelled",
        description:
          "User cancelled the transaction during the wallet signing.",
      });
    } else if (
      e?.message.includes("insufficient funds for intrinsic transaction")
    ) {
      setAlert({
        type: "danger",
        title: "Insufficient funds",
        description:
          "The wallet used for signing the transaction does not have enough funds to complete the transaction.",
      });
    } else if (e?.code === 31337) {
      setAlert({
        type: "danger",
        title: "Transaction Error",
        description: (
          <>
            {e.masslessErrorCode && (
              <div style={{ paddingBottom: "10px" }}>
                Error Code: {e.masslessErrorCode}
              </div>
            )}
            Contact Team on{" "}
            <a target="_blank" href={"https://discord.com/invite/MrZ2cTarmU"}>
              Discord
            </a>
          </>
        ),
      });
    } else {
      setAlert({
        type: "danger",
        title: "Transaction Error",
        description: (
          <>
            Contact Team on{" "}
            <a target="_blank" href={"https://discord.com/invite/MrZ2cTarmU"}>
              Discord
            </a>
          </>
        ),
      });
    }
  };

  const mintInfoText = (saleType, allowance) => {
    switch (saleType) {
      case "AllowListMint":
      case "HoldersMint":
      case "PublicMint":
        return `UP TO ${allowance} ${
          allowance === 1 ? "MINT" : "MINTS"
        } PER TRANSACTION`;
        break;

      case "HoldersGuaranteeMint":
        return `${allowance} ${allowance === 1 ? "MINT" : "MINTS"} AVAILABLE`;
        break;
    }
  };

  const confirmMint = async () => {
    let transactionPassed = true;
    const quantity = mintNum;
    const jungle = jungleNum;
    const saleType = pageInfo.saleType;
    const wlLookup = await Api.whitelist(address);
    const message = constructMessage({
      jungle: utils.parseUnits((jungle * quantity).toString()),
      quantity,
    });
    try {
      const userSig = await signer.signMessage(message);
      const { signature, salt } = await Api.sign({
        userSig,
        quantity,
        jungle: utils.parseUnits((jungle * quantity).toString()).toString(),
        walletAddress: address,
      });
      let interim = pageInfo.jungleValues[jungle] * 10000;
      let calculatedPrice = interim * quantity;
      let totalPrice = (calculatedPrice / 10000).toString();

      console.log(totalPrice);
      switch (saleType) {
        case "AllowListMint":
          totalPrice = (pageInfo.salePrice * quantity).toString();
          await contract
            .allowListMint(signature, salt, wlLookup.wlProof, quantity, {
              value: utils.parseUnits(totalPrice),
            })
            .then(txnAccepted)
            .catch((e) => {
              console.log(e);
              console.log({
                allowList: {
                  signature,
                  salt,
                  merkleProof: wlLookup.wlProof,
                  mintNum,
                  jungleNum,
                  totalPrice,
                },
              });
              throw e;
            });
          break;
        case "HoldersGuaranteeMint":
          await contract
            .holdersGuaranteeMint(
              signature,
              salt,
              utils.parseUnits((jungle * quantity).toString()),
              {
                value: ethers.utils.parseEther(totalPrice),
              }
            )
            .then(txnAccepted)
            // {
            //   setAlert({
            //     type: "success",
            //     title: "Transaction Accepted",
            //     description: tx.hash,
            //   });
            // }
            .catch((e) => {
              console.log(e);
              console.log({
                holdersGuarantee: {
                  signature,
                  salt,
                  merkleProof: wlLookup.wlProof,
                  mintNum,
                  jungleNum,
                  totalPrice,
                },
              });
              throw e;
            });
          break;
        case "HoldersMint":
          await contract
            .holdersMint(
              signature,
              salt,
              utils.parseUnits((jungle * quantity).toString()),
              quantity,
              {
                value: ethers.utils.parseEther(totalPrice),
              }
            )
            .then(txnAccepted)
            // {
            //   setAlert({
            //     type: "success",
            //     title: "Transaction Accepted",
            //     description: tx.hash,
            //   });
            // }
            .catch((e) => {
              console.log({
                holders: {
                  signature,
                  salt,
                  merkleProof: wlLookup.wlProof,
                  mintNum,
                  jungleNum,
                  totalPrice,
                },
              });
              throw e;
            });
          break;
        case "PublicMint":
          await contract
            .publicMint(
              signature,
              salt,
              utils.parseUnits((jungle * quantity).toString()),
              quantity,
              {
                value: ethers.utils.parseEther(totalPrice),
              }
            )
            .then(txnAccepted)
            // {
            //   setAlert({
            //     type: "success",
            //     title: "Transaction Accepted",
            //     description: tx.hash,
            //   });
            // }
            .catch((e) => {
              console.log(e);
              console.log({
                public: {
                  signature,
                  salt,
                  merkleProof: wlLookup.wlProof,
                  mintNum,
                  jungleNum,
                  totalPrice,
                },
              });
              throw e;
            });
          break;
        default:
          break;
      }
    } catch (e) {
      console.log({ e });
      if (e?.error?.data?.originalError?.data) {
        const masslessErrorCode = e.error.data.originalError.data.slice(0, 10);
        console.log(masslessErrorCode);
        e.masslessErrorCode = masslessErrorCode;
        e.code = 31337;
      }
      if (e?.isAxiosError) {
        e.masslessErrorCode = "0x8c114" + e?.response?.status;
        e.code = 31337;
      }
      txnFailed(e);
      transactionPassed = false;
    }

    const info = await Api.saleInfo(address);
    /*console.log({
      jungleNum,
      pageInfoJungle: pageInfo.jungle,
      transactionPassed,
      newJungle: (parseFloat(pageInfo.jungle) - jungleNum).toFixed(1),
      showPayWithJungle,
    });*/
    let newPageInfo = { ...pageInfo, ...info };
    if (transactionPassed) {
      newPageInfo.jungle = (
        parseFloat(pageInfo.jungle) -
        jungleNum * mintNum
      ).toFixed(1);

      // And reset which jungle button
      setJungleNum(null);

      // If we're on holders guarantee mint, make the allowance now 0
      if (pageInfo.saleType === "HoldersGuaranteeMint") {
        newPageInfo.allowance = 0;
      }
    }
    setPageInfo(newPageInfo);
  };

  useEffect(() => {
    if (!web3Modal.cachedProvider) {
      history.push("/jfmc");
    }
  }, [web3Modal.cachedProvider]);

  useEffect(() => {
    (async () => {
      if (address) {
        const info = await Api.saleInfo(address);
        const additionaInfo = additionaPageInfo[info.saleType];

        info.jungleValues = { 0: 0.08, 75: 0.06, 150: 0.04, 300: 0 };
        if (info.saleType === "PublicMint") {
          info.jungleValues = { 0: 0.1, 90: 0.075, 185: 0.05, 375: 0 };
        }

        setPageInfo({ ...info, ...additionaInfo });
        setMintNum(1);
      }
    })();
  }, [address]);

  if (!pageInfo.saleType) {
    return (
      <MintLayout>
        <CenteredMsg>Loading...</CenteredMsg>
      </MintLayout>
    );
  }

  return (
    <MintLayout>
      <MintContent>
        <div>
          <Logo src={LOGO} alt="" />
          <ProgressBarContainer>
            <ProgressBar percent={parseInt(mintedPercent)}>
              {isPreSale && <ProgressBarMark />}
            </ProgressBar>
            <div>
              {pageInfo.totalMinted} / {maxSupply} Minted
            </div>
          </ProgressBarContainer>
          <Title>JFMC {pageInfo.title} MINT</Title>
        </div>
        <SelectMintContainer>
          {!disabledMsg ? (
            <>
              <GridHalf>
                {showPayWithJungle && pageInfo.allowance != 0 && (
                  <>
                    <GridHalfColumn>
                      <b>{parseFloat(pageInfo.jungle).toFixed(2)}</b> $JUNGLE
                      AVAILABLE
                    </GridHalfColumn>
                    <GridHalfColumn></GridHalfColumn>
                  </>
                )}
                {(pageInfo.saleType != "AllowListMint" ||
                  (pageInfo.saleType == "AllowListMint" &&
                    pageInfo.eligible)) && (
                  <GridHalfColumn bold={!pageInfo.jungle}>
                    {mintInfoText(pageInfo.saleType, pageInfo.allowance)}
                  </GridHalfColumn>
                )}
              </GridHalf>
              <SelectMintBtns>
                {[...Array(pageInfo.allowance).keys()]
                  .map((n) => n + 1)
                  .map((num) => (
                    <MintBtn
                      onClick={() => {
                        setMintNum(num);
                        setJungleNum(0);
                      }}
                      className={mintNum === num ? "selected" : ""}
                    >
                      MINT {num}
                    </MintBtn>
                  ))}
              </SelectMintBtns>
            </>
          ) : (
            <DisabledMessage>{disabledMsg}</DisabledMessage>
          )}
        </SelectMintContainer>
        {showPayWithJungle && !disabledMsg && (
          <JungleTokensContainer>
            <JungleTokensTitle>
              Use Your {parseFloat(pageInfo.jungle).toFixed(2)} $JUNGLE Tokens
            </JungleTokensTitle>
            <JungleBtns>
              {Object.entries(pageInfo.jungleValues).map(([num, ether]) => {
                num = Number(num);
                return (
                  <JungleBtn
                    disabled={parseFloat(pageInfo.jungle) < num * mintNum}
                    onClick={() => setJungleNum(num)}
                    className={jungleNum === num ? "selected" : ""}
                  >
                    <img src={COIN} alt="" />
                    {num * mintNum}
                  </JungleBtn>
                );
              })}
            </JungleBtns>
          </JungleTokensContainer>
        )}
        {pageInfo.eligible && !disabledMsg && (
          <GridHalf twoLines={pageInfo.priceTwoLines}>
            {pageInfo.saleType != "HoldersGuaranteeMint" && (
              <>
                <GridHalfColumn>
                  Price per Mint:
                  <b>
                    {" "}
                    {jungleNum === 90
                      ? parseFloat(pricePerToken).toFixed(3)
                      : parseFloat(pricePerToken).toFixed(2)}{" "}
                    ETH
                  </b>
                </GridHalfColumn>
                <GridHalfColumn />
              </>
            )}
            <GridHalfColumn>
              Total Price:
              <b>
                {" "}
                {jungleNum === 90
                  ? parseFloat(priceTotal).toFixed(3)
                  : parseFloat(priceTotal).toFixed(2)}{" "}
                ETH
              </b>
            </GridHalfColumn>
          </GridHalf>
        )}
        <div>
          {!disabledMsg && (
            <ButtonAction
              className="inverse"
              disabled={!confirmEnabled}
              color="#E61E28"
              onClick={confirmMint}
            >
              CONFIRM
            </ButtonAction>
          )}
        </div>
      </MintContent>
      <MintFooter>
        {pageInfo.allowance != 0 && (
          <GweiLogoWrap>
            <GweiLogo src={GWEI_LOGO} alt="gwei logo" />
            {pageInfo.gasPrice} GWEI
          </GweiLogoWrap>
        )}
        <EtherscanLogoWrap>
          <EtherscanLogo src={ETHERSCAN_LOGO} alt="etherscan logo" />
          <a target="_blank" href={`https://etherscan.io/address/${addr}`}>
            Contract: {short}
          </a>
        </EtherscanLogoWrap>
      </MintFooter>
      {alert && (
        <AlertMsg
          onClose={() => setAlert()}
          type={alert.type}
          title={alert.title}
          description={alert.description}
        />
      )}
    </MintLayout>
  );
}
