import McMintHero from "../components/McMintHero";

const MotorClubMintPage = () => {
  return (
    <div>
      <header>
        <McMintHero />
      </header>
    </div>
  );
};

export default MotorClubMintPage;
