import React from "react";
import styled from "styled-components";
import Kenno from "../static/images/Kenno.jpeg";
import Macho from "../static/images/Macho.jpeg";
import POH from "../static/images/Poh.jpeg";
import MrBit from "../static/images/MrBit.png"
import Laughing from "../static/images/Laughing.png"
import Gaz from "../static/images/Gaz.png";
import Dwayne from "../static/images/Dwayne.png";
import Zozo from "../static/images/Zozo.png";
//
// import FFWILL from "../static/images/FF_WILLFAN.png";
// import FFPRINCE from "../static/images/FF_PRINCE.png";
// import FFNATURAL from "../static/images/FF_NATURAL.png";
// import FFMACHO from "../static/images/FF_MACHO.png";
// import Ed from "../static/images/Ed.jpeg";
// import Lebkuchen from "../static/images/Lebkuchen.jpeg";
// import Jordbrett from "../static/images/Jordbrett.jpeg";




import Heading from "./Heading";

const SmallText = styled.div`
  font-size: 20px;
  font-weight: 400;
`;

const BoldedSmallText = styled.div`
  font-size: 25px;
  font-weight: 800;
  margin: 34px 0 0 0;
  color: orange;
  font-family: "Montserrat", sans-serif;
`;

const ImgTeam = styled.img`
  width: 100%;
  border-radius: 37px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 34px;
  text-align: center;
  grid-row-gap: 34px;
`;

const Container = styled.div`
  padding: 0% 8% 4%;
  background: #000;
  color: #fff;

  @media screen and (max-width: 1200px) {
    & ${Grid} {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media screen and (max-width: 600px) {
    & ${Grid} {
      grid-template-columns: 1fr;
    }
  }
`;

export default function SectionTeam() {
  return (
    <Container id="team">
      <Heading border={true}>MEET THE TEAM</Heading>
      <Grid>
        <div>
          {/*<a href="https://twitter.com/THEPRINCE______">*/}
            <ImgTeam src={Kenno} alt="" />
          {/*</a>*/}
          <div>
            <BoldedSmallText>Kenno</BoldedSmallText>
            <SmallText>Project Head</SmallText>
          </div>
        </div>
        <div>
          {/*<a href="https://twitter.com/THEPRINCE______">*/}
            <ImgTeam src={Macho} alt="" />
          {/*</a>*/}
          <div>
            <BoldedSmallText>Macho</BoldedSmallText>
            <SmallText>Community Manager</SmallText>
          </div>
        </div>
        {/* <div>
          <a href="https://twitter.com/THEPRINCE______">
            <ImgTeam src={Lebkuchen} alt="" />
          </a>
          <div>
            <BoldedSmallText>Lebkuchen</BoldedSmallText>
            <SmallText>Admin/Discord Builds</SmallText>
          </div>
        </div> */}
        <div>
          {/*<a href="https://twitter.com/G1CSTB3">*/}
            <ImgTeam src={POH} alt="" />
          {/*</a>*/}
          <div>
            <BoldedSmallText>Poh</BoldedSmallText>
            <SmallText>Head Mod</SmallText>
          </div>
        </div>
        <div>
          {/*<a href="https://twitter.com/G1CSTB3">*/}
            <ImgTeam src={Laughing} alt="" />
          {/*</a>*/}
          <div>
            <BoldedSmallText>Laughing Buddha</BoldedSmallText>
            <SmallText>Project Advisor</SmallText>
          </div>
        </div>
        <div>
          {/*<a href="https://twitter.com/G1CSTB3">*/}
            <ImgTeam src={MrBit} alt="" />
          {/*</a>*/}
          <div>
            <BoldedSmallText>MrBit</BoldedSmallText>
            <SmallText>Tech Head</SmallText>
          </div>
        </div>

        <div>
          {/*<a href="https://twitter.com/G1CSTB3">*/}
            <ImgTeam src={Gaz} alt="" />
          {/*</a>*/}
          <div>
            <BoldedSmallText>Gaz</BoldedSmallText>
            <SmallText>Community Engagement</SmallText>
          </div>
        </div>
        <div>
          {/*<a href="https://twitter.com/G1CSTB3">*/}
            <ImgTeam src={Dwayne} alt="" />
          {/*</a>*/}
          <div>
            <BoldedSmallText>Dwayne</BoldedSmallText>
            <SmallText>Marketing Manager</SmallText>
          </div>
        </div>
        <div>
          {/*<a href="https://twitter.com/G1CSTB3">*/}
            <ImgTeam src={Zozo} alt="" />
          {/*</a>*/}
          <div>
            <BoldedSmallText>Zozo</BoldedSmallText>
            <SmallText>Entertainment Manager</SmallText>
          </div>
        </div>
        {/* <div>
          <a href="https://twitter.com/Pale_Blue_Dot_S">
            <ImgTeam src={Jordbrett} alt="" />
          </a>
          <div>
            <BoldedSmallText>Jordbrett</BoldedSmallText>
            <SmallText>Head of Engagement</SmallText>
          </div>
        </div> */}
        {/* <div>
          <a href="https://twitter.com/hellowillfan">
            <ImgTeam src={Ed} alt="" />
          </a>
          <div>
            <BoldedSmallText>Ed</BoldedSmallText>
            <SmallText>Chief of Research</SmallText>
          </div>
        </div> */}
      </Grid>
    </Container>
  );
}
