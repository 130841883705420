import styled from "styled-components";
import McClaim from "../components/McClaim";

const Container = styled.div`
  & * {
    font-family: "Montserrat";
  }
`;

const McPage = () => {
  return (
    <Container>
      <header>
        <McClaim />
      </header>
    </Container>
  );
};

export default McPage;
