import numeral from "numeral";
import { useEffect, useState } from "react";
import styled from "styled-components";
import ETHICON from "../static/images/eth-icon.svg";

export const MetricsTitle = styled.div`
  font-size: 20px;
  text-align: left;
  grid-area: header;
  line-height: 20px;
  & b {
    white-space: nowrap;
  }
`;

export const MetricsContainer = styled.div`
  position: relative;
  padding: 72px 8% 30px 8%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: "header content1 content2 content3";
  align-items: center;
  color: #fff;
  & img {
    width: 14.25px !important;
    margin-right: 8px;
    vertical-align: unset !important;
  }
  & > div {
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;

    &:nth-child(n+2) {
      text-align: center;
    }
    &:nth-child(2) {
      grid-area: content1;
    }
    &:nth-child(3) {
      grid-area: content2;
    }
    &:nth-child(4) {
      grid-area: content3;
    }
    & > div {
      font-weight: 400;
      font-size: 20px;
    }
  }
  @media screen and (max-width: 900px) {
    padding-right: 5%;
    padding-left: 5%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      "header header header"
      "content1 content2 content3";

    & ${MetricsTitle} {
      margin-bottom: 20px;
      font-size: 16px;
      & > div {
        margin-left: 6px;
        display: inline-block;
      }
    }

    & > div {
      font-size: 25px;
      & > div {
        font-size: 16px;
        white-space: nowrap;
      }
      &:nth-child(2) {
        justify-self: start;
      }
      &:nth-child(4) {
        justify-self: end;
      }
    }
  }
`;

const collections = {
  genesis: { link: 'jungle-freaks-genesis', name: 'GENESIS' },
  jfmc: { link: 'jungle-freaks-motor-club', name: 'MOTOR CLUB' },
};

const McMetrics = ({ collection }) => {
  const [items, setItems] = useState();
  const [owners, setOwners] = useState();
  const [floorprice, setFloorPrice] = useState();
  const [volume, setVolume] = useState();
  const { link, name } = collections[collection];

  useEffect(() => {
    LoadData();
  }, []);

  const LoadData = async () => {
    const response = await fetch(
      `https://api.opensea.io/api/v1/collection/${link}/stats`
    );
    const data = await response.json();

    setItems(numeral(data.stats.count).format("0.0a"));
    if (collection === "genesis") {
      setOwners(numeral(4900).format("0.0a"));
    } else {
      setOwners(numeral(data.stats.num_owners).format("0.0a"));
    }
    setFloorPrice(numeral(data.stats.floor_price).format("0.00a"));
    setVolume(numeral(data.stats.total_volume).format("0.0a"));
  };

  return (
    <MetricsContainer>
      <MetricsTitle>
        <b>JUNGLE FREAKS</b>
        <div>{name} COLLECTION:</div>
      </MetricsTitle>
      <div>
        {owners}
        <div>Owners</div>
      </div>
      {/* <div>
        <img src={ETHICON} alt="" />
        {floorprice}
        <div>Floor price</div>
      </div> */}
      <div>
        <img src={ETHICON} alt="" />
        {volume}
        <div>Volume traded</div>
      </div>
    </MetricsContainer>
  );
};

export default McMetrics;
