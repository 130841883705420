import React from "react";
import styled from "styled-components";
import IMAGE_ONE from "../static/images/one.png";
import IMAGE_TWO from "../static/images/two.png";
import IMAGE_THREE from "../static/images/three.png";
import IMAGE_FOUR from "../static/images/four.png";

import "../static/css/styles.css";

export default function SectionThree() {
  const SectionItemsWrapper = styled.div`
    overflow-x: hidden;
    display: grid;
    background-color: transparent;
    color: #fff;
    padding: 0% 0%;
    max-width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "item-sec-3-1 item-sec-3-2 item-sec-3-3 item-sec-3-4";

    /* Mobile */
    @media screen and (min-width: 300px) {
      overflow-x: hidden;
      display: grid;
      background-color: transparent;
      color: #fff;
      padding: 0% 0%;
      max-width: 100%;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas: "item-sec-3-1 item-sec-3-2" "item-sec-3-3 item-sec-3-4";
    }

    /* Tablet */
    @media screen and (min-width: 680px) {
      overflow-x: hidden;
      display: grid;
      background-color: transparent;
      color: #fff;
      padding: 0% 0%;
      max-width: 100%;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      grid-template-areas: "item-sec-3-1 item-sec-3-2 item-sec-3-3 item-sec-3-4";
    }
  `;

  return (
    <div>
      <SectionItemsWrapper>
        <div className="section-three-images">
          <img src={IMAGE_ONE} alt="" />
        </div>

        <div className="section-three-images">
          <img src={IMAGE_TWO} alt="" />
        </div>

        <div className="section-three-images">
          <img src={IMAGE_THREE} alt="" />
        </div>

        <div className="section-three-images">
          <img src={IMAGE_FOUR} alt="" />
        </div>
      </SectionItemsWrapper>
    </div>
  );
}
