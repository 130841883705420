import styled from "styled-components";
import TESLA from "../static/images/tesla-bg.svg";
import ETH from "../static/images/eth-bg.svg";

const Title = styled.div`
  font-weight: 800;
  font-size: 51px;
  color: #E61E28;
  line-height: 19px;
`;

const Subtitle = styled.div`
  font-size: 73px;
  font-weight: 800;
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  margin: 20px 0 40px 0;
  & small {
    color: #7c7c7c;
    display: block;
    font-size: 14px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Button = styled.button`
  width: 201px;
  width: min(201px, 40%);
  height: 201px;
  background: #F1F1F1;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  color: #111111;
  position: relative;
  font-weight: 800;
  font-size: 25px;
  cursor: pointer;

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Observation = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  margin-top: 59px;
  text-align: center;
`;

const Content = styled.div`
  z-index: 1;

  @media screen and (max-width: 600px) {
    text-align: center;
    ${Title} {
      font-size: 30px;
    }
    ${Subtitle} {
      font-size: 42px;
    }
  }
`;

const McClaimCongratulations = ({ onSelectPrize }) => {
  return (
    <>
      <Content>
        <Title>CONGRATULATIONS</Title>
        <Subtitle>YOU'VE WON!</Subtitle>
        <Description>
          SELECT YOUR PRIZE BELOW
          <small>CHOOSE WISELY</small>
        </Description>
        <ButtonsContainer>
          <Button onClick={() => onSelectPrize('Tesla')}>
            <div><img src={TESLA} /></div>
            <div>TESLA</div>
          </Button>
          <Button onClick={() => onSelectPrize('ETH')}>
            <div><img src={ETH} /></div>
            <div>ETH</div>
          </Button>
        </ButtonsContainer>
        <Observation>* Tesla only applicable to US residents</Observation>
      </Content>
    </>
  );
};

export default McClaimCongratulations;