import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import BG from "../static/images/banner4.png";
import AlertMsg, { AlertMsgContainer } from "./AlertMsg";
import McClaimCongratulations from "./McClaimCongratulations";
import McClaimForm from "./McClaimForm";
import McClaimFinish from "./McClaimFinish";
import Api from "../Api";
import McClaimFormConfirmation from "./McClaimFormConfirmation";
import useWeb3Connect from "../useWeb3Connect";
import McClaimAdmin from "./McClaimAdmin";
import CONFETTI from "../static/images/confetti.png";

const ImgConfetti = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
`;

const BgImage = styled.div`
  color: #fff;
  box-sizing: border-box;
  background: transparent url("${BG}") no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;

  & ${AlertMsgContainer} {
    background: transparent;
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
`;

const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  font-size: 23px;
`;

const McClaimLayout = ({ children }) => (
  <div style={{ backgroundColor: "black" }}>
    <BgImage>{children}</BgImage>
  </div>
);

export default function McClaim() {
  const history = useHistory();
  const [alert, setAlert] = useState();
  const [prize, setPrize] = useState();
  const [form, setForm] = useState();
  const [formConfirm, setFormConfirm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [claimInfo, setClaimInfo] = useState();
  const isAdmin = claimInfo?.type === 'admin';
  const { web3Modal, onConnect, signer, address: walletAddress } = useWeb3Connect();

  useEffect(() => {
    if (!walletAddress || !signer || !loading) {
      return false;
    }

    (async () => {
      try {
        const { salt } = await Api.saleInfo(walletAddress);
        const message = `Wallet Login\n\nLogin to claim the Tesla.\nsecurity: ${salt}`;
        const userSig = await signer.signMessage(message);
        const info = await Api.checkClaim({ userSig, walletAddress });
        setLoading(false);

        if (info.claimStatus === "claimed" && info.type !== 'admin') {
          alertDangerStart("THIS PRIZE HAS ALREADY BEEN CLAIMED");
        } else if (info.status === "notWon") {
          alertDangerStart("YOU DON'T HAVE THE WINNING NFT IN YOUR WALLET");
        } else {
          setClaimInfo(info);
        }

      } catch (e) {
        let msg = "THE SIGNATURE FAILED";
        if(e && e.code === 4001) {
          msg = e.message;
        }

        console.error(e);
        setLoading(false);
        alertDangerStart(msg);
      }
    })();
  }, [signer, walletAddress, loading]);

  // if user is not loggen in
  useEffect(() => {
    if (!web3Modal.cachedProvider) {
      onConnect().then(res => {
        if (!res) {
          setLoading(false);
          alertDangerStart("UNABLE TO CONNECTO TO YOUR WALLET");
        }
      });
    }
  }, [web3Modal.cachedProvider]);

  const alertDangerStart = (title) => setAlert({
    onClose: onBack,
    type: "danger",
    title,
    description: "If you feel this is an error, please open a support ticket in JF discord.",
  });

  const onBack = () => history.push('jfmc');

  const submitForm = async () => {
    setFormConfirm(true);

    try {
      const { salt } = await Api.saleInfo(walletAddress);
      const msg = `Validate Signature\n\nClaim the prize details.\nEmail: ${form.email}${form.phoneNumber ? '\nPhone: ' + form.phoneNumber : ''}\nPrize: ${prize}\nsecurity: ${salt}`;
      const userSig = await signer.signMessage(msg);
      const claimDetails = {
        email: form.email,
        walletAddress,
        choice: prize
      };

      if (form.phoneNumber) {
        claimDetails.phoneNumber = form.phoneNumber;
      }

      const res = await Api.submitClaim({
        userSig,
        walletAddress,
        claimDetails
      });
      console.log(res);
      setSuccess(true);

    } catch (e) {
      console.error(e);
      failSignOff();
    }
  }

  const failSignOff = () => {
    setAlert({
      onClose: () => {
        setAlert();
        setForm();
      },
      type: "danger",
      title: "Wallet Sign-Off Error",
      description: <>Please try again.<br />If this persists,  please open a support ticket in JF discord.</>,
    });
  };

  if (loading) {
    return (
      <McClaimLayout>
        <Loading>Loading...</Loading>
      </McClaimLayout>
    );
  }

  if (alert) {
    return (
      <McClaimLayout>
        <AlertMsg
          onClose={alert.onClose}
          type={alert.type}
          title={alert.title}
          description={alert.description}
        />
      </McClaimLayout>
    );
  }

  if (isAdmin) {
    return (
      <McClaimLayout>
        <Container>
          <McClaimAdmin info={claimInfo} />
        </Container>
      </McClaimLayout>
    );
  }

  return (
    <McClaimLayout>
      <Container>
        <ImgConfetti src={CONFETTI} alt="" />
        {!prize && (
          <McClaimCongratulations onSelectPrize={setPrize} />
        )}
        {prize && !form && (
          <McClaimForm prize={prize} onSubmit={setForm} onBack={() => setPrize()} />
        )}
        {form && !formConfirm && (
          <McClaimFormConfirmation prize={prize} onConfirm={submitForm} onBack={() => setForm()} />
        )}
        {success && (
          <McClaimFinish prize={prize} form={form} />
        )}
      </Container>
    </McClaimLayout>
  );
}
