import styled from "styled-components";
import ALERT_CLOSE from "../static/images/icons/alert-close.png";
import ALERT_SUCCESS from "../static/images/icons/alert-success.png";
import ALERT_DANGER from "../static/images/icons/alert-error.png";

export const AlertMsgContainer = styled.div`
  background: rgba(0, 0, 0, 0.45);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
`;

const AlertMsgContent = styled.div`
  position: relative;
  width: 350px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  background: #f1f1f1;
  border: 1px solid #grey;
  text-align: center;
  padding: 30px 10px;
`;

const AlertMsgIcon = styled.img`
  width: 41px;
`;

const AlertMsgTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin: 14px 0;
`;

const AlertMsgDesc = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  word-wrap: break-word;
  margin-top: 8px;
`;

const CloseIcon = styled.img`
  position: absolute;
  cursor: pointer;
  right: 10px;
  top: 10px;
  width: 11px;
  padding: 5px;
`;

const icons = {
  success: ALERT_SUCCESS,
  danger: ALERT_DANGER,
};

const AlertMsg = ({ onClose, type, title, description }) => {
  return (
    <AlertMsgContainer>
      <AlertMsgContent>
        <CloseIcon src={ALERT_CLOSE} onClick={onClose} alt="" />
        <AlertMsgIcon src={icons[type]} alt="" />
        <AlertMsgTitle>{title}</AlertMsgTitle>
        {description && <AlertMsgDesc>{description}</AlertMsgDesc>}
      </AlertMsgContent>
    </AlertMsgContainer>
  );
};

export default AlertMsg;
