import React from "react";
import styled from "styled-components";
import RED_FLAG from "../static/images/jfmc-red-flag.svg";
import DISCORD from "../static/images/icons/discord.svg";
import LAMBO from "../static/images/jfmc-lambo.png";
import BG from "../static/images/jfmc-hero-bg.png";
import ETHICON from "../static/images/eth-icon.svg";
import HeroTitle from "./HeroTitle";
import McNavbar from "./McNavbar";
import McHeroWhy from "./McHeroWhy";
import McHeroDates from "./McHeroDates";
import Button from "./Button";
import useWeb3Connect from "../useWeb3Connect";
import { Link, useHistory } from "react-router-dom";
import McMetrics, { MetricsContainer } from "./McMetrics";

const ButtonBold = styled(Button)`
  width: auto;
  padding: 0 19px;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const HeroVerticalLine = styled.div`
  background: #fff;
  position: absolute;
  width: 1px;
  height: 125%;
  top: -7%;
  left: 0;
`;

const HeroText = styled.div`
  position: relative;
  padding-left: 50px;
  z-index: 1;
`;

const McHeroTitle = styled(HeroTitle)`
  max-width: 767px;
  margin: 30px 0 50px 0;
  & span {
    font-weight: normal;
  }
`;

const McHeroParagraph = styled.div`
  font-size: 16px;
  max-width: 39%;
  margin: 23px 0 35px 0;
`;

const EthImgContainer = styled.div``;

const ImgLamboContainer = styled.div`
  position: relative;
  & img {
    position: absolute;
    right: -15%;
    bottom: calc(-34vw + 218px);
    width: 720%;
  }
`;

const MintingDateImg = styled.div`
  background: transparent url("${RED_FLAG}");
  background-repeat: no-repeat;
  height: 32px;
  width: 286px;
  font-weight: 800;
  font-size: 20px;
  padding-left: 16px;
  margin: 30px 0;
  display: flex;
  align-items: center;
`;

const TopGrid = styled.div`
  margin-bottom: 11vw;
  display: grid;
  grid-template-columns: 1.8fr 0.3fr;
`;

const HeroContainer = styled.div`
  padding: 135px 92px 10px 125px;
  & ${MetricsContainer} {
    padding: 72px 50px 30px 50px;
  }
`;

const HeroImage = styled.div`
  color: #fff;
  overflow-x: hidden;
  min-height: 800px;
  box-sizing: border-box;
  background: transparent url("${BG}") no-repeat;
  background-size: 100%;

  @media screen and (max-width: 900px) {
    & ${McHeroParagraph} {
      padding-right: 5%;
      padding-left: 5%;
    }
    & ${TopGrid} {
      grid-template-columns: 1fr;
      margin-bottom: 27vw;
      & ${ImgLamboContainer} {
        height: 50vw;
        & img {
          position: relative;
          margin-left: -100%;
          top: -91%;
          width: 271%;
        }
      }
      & ${McHeroParagraph} {
        margin-top: 40px;
        max-width: none;
      }
      & ${HeroText} {
        padding: 0;
      }
    }

    & ${ButtonBold} {
      margin-left: 5%;
    }
    & ${HeroContainer} {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
      & ${MetricsContainer} {
        padding-right: 5%;
        padding-left: 5%;
      }
    }
    & ${McHeroTitle} {
      text-align: left;
      padding: 0 5%;
      & > span {
        display: block;
      }
    }
    & ${HeroVerticalLine}, & ${EthImgContainer} {
      display: none;
    }
    & ${EthImgContainer} {
      text-align: center;
    }
    & ${ImgLamboContainer} {
      display: block;
    }
  }
`;

const HeroButton = ({ soldOut, claimEnabled }) => {
  const { onConnect, connected } = useWeb3Connect();
  const history = useHistory();

  if (soldOut && claimEnabled) {
    return (
      <ButtonBold color="#E61E28" onClick={() => history.push("jfmc-claim")}>
        CLAIM YOUR TESLA
      </ButtonBold>
    );
  }

  if (soldOut) {
    return (
      <a
        href="https://discord.com/invite/junglefreaksnft"
        target="_blank"
        style={{ textDecoration: "none" }}
      >
        <ButtonBold color="#E61E28">
          <img src={DISCORD} alt="" />
          JOIN DISCORD
        </ButtonBold>
      </a>
    );
  }

  if (connected) {
    return (
      <Link to="jfmc-mint" style={{ textDecoration: "none" }}>
        <ButtonBold color="#E61E28">GO TO MINT</ButtonBold>
      </Link>
    );
  }

  return (
    <ButtonBold color="#E61E28" onClick={onConnect}>
      CONNECT WALLET
    </ButtonBold>
  );
};

export default function Hero({ soldOut, claimEnabled }) {
  return (
    <div style={{ backgroundColor: "black" }}>
      <HeroImage>
        <McNavbar />
        <HeroContainer>
          <TopGrid>
            <HeroText>
              <HeroVerticalLine />
              <EthImgContainer>
                <img src={ETHICON} alt="" />
              </EthImgContainer>
              <MintingDateImg>
                {soldOut ? "SOLD OUT!" : "MINTING NOW"}
              </MintingDateImg>
              <McHeroTitle>
                JUNGLE FREAKS
                <span> MOTOR CLUB</span>
              </McHeroTitle>
              <McHeroParagraph>
                A collection of 8,888 Hand Drawn Cars. These NFT's act as a
                metaverse pass for car related games with embedded performance
                related metadata.
              </McHeroParagraph>
              {claimEnabled && (
                <McHeroParagraph style={{ marginBottom: '20px' }}>
                  Have the winning NFT?
                </McHeroParagraph>
              )}
              <HeroButton soldOut={soldOut} claimEnabled={claimEnabled} />
            </HeroText>
            <ImgLamboContainer>
              <img src={LAMBO} alt="" />
            </ImgLamboContainer>
          </TopGrid>
          {soldOut ? <McMetrics collection="jfmc" /> : <McHeroDates />}
          <McHeroWhy />
        </HeroContainer>
      </HeroImage>
    </div>
  );
}
