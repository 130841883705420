import "../App.css";
import HeroCaroussel from "../components/HeroCaroussel";
import SectionBuy from "../components/SectionBuy";
import SectionThree from "../components/SectionThree";
import SectionRoadmap from "../components/SectionRoadmap";
import SectionTeam from "../components/SectionTeam";
import SectionPartners from "../components/SectionPartners";
import Footer from "../components/Footer";
import styled from "styled-components";
import SectionStakeNow from "../components/SectionStakeNow";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import Navbar from "../components/Navbar";

const HR = styled.div`
  border: 0.1px #414141 solid;
`;

const OrangeSeparator = styled.div`
  height: 10px;
  background: linear-gradient(91.34deg, #f59e0c 9.84%, #e61e28 79.06%);
`;

const HomePage = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 0);
    }
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <Navbar absolute={true} />
      <HeroCaroussel />
      <OrangeSeparator />
      <SectionStakeNow />
      <SectionBuy />
      <SectionThree />
      <SectionRoadmap />
      <SectionTeam />
      <SectionPartners />
      <HR />
      <Footer cbBg="#F59E0C" />
    </div>
  );
};

export default HomePage;
