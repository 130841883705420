import React from "react";
import styled from "styled-components";
import { Button, Description, Form, Title, TwoButtons } from "./McClaimForm";

const CheckboxContainer = styled.div`
  font-size: 14px;
  display: flex;
  margin-top: 30px;
  & input {
    margin-right: 15px;
  }
`;

export const McClaimFormConfirmation = ({ prize, onConfirm, onBack }) => {
  const isTesla = prize === 'Tesla';

  const submit = (e) => {
    e.preventDefault();
    onConfirm(true);
  };

  return (
    <Form onSubmit={submit}>
      <Title>Are You Sure?</Title>
      <Description>
        <p>
          Please confirm that you have chosen to claim the
          {isTesla ? (
            <><b> TESLA automobile, are you sure?</b> (US residents only).</>
          ) : (
            <b> ETHEREUM, are you sure?</b>
          )}
        </p>
        <p>
          Once confirmed you will be met with one more wallet validation before your prize selection is finalized.
        </p>
        <p>
          You will not be able to change your preference after the wallet validation is complete.
        </p>
      </Description>
      {isTesla && (
        <CheckboxContainer>
          <input type="checkbox" required name="us_resident" />
          I confirm that I am a legal US resident and will be able to provide proof of that.
        </CheckboxContainer>
      )}
      <CheckboxContainer>
        <input type="checkbox" required name="form_confirm" />
        <span>
          I confirm that I agree to the <a href="" target="_blank">JFMC Tesla Terms and Conditions</a>
        </span>
      </CheckboxContainer>
      <TwoButtons>
        <Button type="submit">Confirm</Button>
        <Button outlined={true} type="button" onClick={onBack}>Go Back</Button>
      </TwoButtons>
    </Form>
  );
};

export default McClaimFormConfirmation;
