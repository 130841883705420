import styled from "styled-components";
import "../App.css";
import McHero from "../components/McHero";
import Footer from "../components/Footer";
import McLookingToJoin from "../components/McLookingToJoin";
import SectionMcCars from "../components/SectionMcCars";
import McGameHighlight from "../components/McGameHighlight";

const HR = styled.div`
  border: 0.1px #414141 solid;
`;

const Container = styled.div`
  & * {
    font-family: "Montserrat";
  }
`;

const McPage = () => {
  const claimEnabled = process.env.REACT_APP_CLAIM_TESLA === "1";
  const soldOut = true;
  return (
    <Container>
      <header>
        <McHero soldOut={soldOut} claimEnabled={claimEnabled} />
      </header>
      <McGameHighlight />
      <SectionMcCars />
      {soldOut && <McLookingToJoin />}
      <HR />
      <Footer />
    </Container>
  );
};

export default McPage;
