import React from "react";
import { Form, Input } from "./McClaimForm";
import styled from "styled-components";

const InputDisabled = styled(Input)`
  color: #000;
`;

const McClaimAdmin = ({ info }) => {
  console.log({info})
  const { claimStatus, claimDetails = {} } = info;

  if (claimDetails === 'pending') {
    return (
      <Form>
        Prize not claimed yet.
      </Form>
    );
  }

  return (
    <Form hasHeader={true}>
      <InputDisabled disabled value={claimStatus} />
      <InputDisabled disabled value={claimDetails.walletAddress} placeholder="Wallet" />
      <InputDisabled disabled value={claimDetails.email} placeholder="E-mail Address" />
      <InputDisabled disabled value={claimDetails.phoneNumber} placeholder="Phone Number" />

      <InputDisabled disabled value={claimDetails.choice} placeholder="Prize Claimed" />
      <br /><br /><br />
    </Form>
  );
};

export default McClaimAdmin