import styled from "styled-components";
import CAR1 from "../static/images/jfmc-car-1.png";
import CAR2 from "../static/images/jfmc-car-2.png";
import CAR3 from "../static/images/jfmc-car-3.png";
import CAR4 from "../static/images/jfmc-car-4.png";

const GridCars = styled.div`
  display: grid;
  background: #000;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  & img {
    width: 100%;
  }

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export default function SectionMcCars() {
  return (
    <GridCars>
      <img src={CAR1} alt="" />
      <img src={CAR2} alt="" />
      <img src={CAR3} alt="" />
      <img src={CAR4} alt="" />
    </GridCars>
  );
}