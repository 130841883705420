import React from "react";
import styled from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import JFMC_LOGO from "../static/images/jfmc-logo-lg.png";
import LOGO from "../static/images/logo-transparent-white.svg";
import BANNER_JUNGLE from "../static/images/banner.jpg";
import BANNER from "../static/images/banner3.png";
import SLIME from "../static/images/slime.png";
import ETHICON from "../static/images/eth-icon.svg";
import DISCORD from "../static/images/icons/discord.svg";
import FALLOUT_LOGO from "../static/images/fallout-logo.png";

import "../static/css/styles.css";
import HeroTitle from "./HeroTitle";
import { ButtonAction } from "./Button";
import McMetrics from "./McMetrics";
import { Link } from "react-router-dom";
import Flag from "./Flag";

const GridImg = styled.div`
  display: flex;
  align-items: center;
  max-height: 90%;
  & img {
    padding: 20px 0;
    width: 100%;
  }
`;

const GridText = styled.div`
  color: #fff;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const GridVertialLine = styled.div`
  width: 1px;
  background: #fff;
  align-self: normal;
`;

export const McHeroParagraph = styled.div`
  font-size: 16px;
  margin: 23px 0 38px 0;
`;

const EthImgContainer = styled.div`
  & img {
    width: auto !important;
  }
`;

const ButtonWrapper = styled.div`
  & a {
    text-decoration: none;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 0.7fr auto 1.3fr;
  padding: 20px 8% 0 8%;
  gap: 3vw;
  text-align: left;
  align-items: center;
`;

const BlackOverlay = styled.div`
  background: #000;
  height: 100%;
`;

const BlackAlphaOverlay = styled.div`
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
`;

const Content = styled.div`
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const Container = styled.div`
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100%;
  & ${HeroTitle} {
    margin-top: 9px;
  }
`;

const ContainerFallout = styled(Container)`
  background-size: 774px;
  background-position: -55px top;
  background-repeat: repeat-x;
  background-image: url("${SLIME}");

  & ${Grid} {
    padding-top: 90px;
  }
`;

const ContainerJf = styled(Container)`
  background-image: url("${BANNER_JUNGLE}");
  & ${HeroTitle} {
    font-size: 70px;
  }
`;

const ContainerJfmc = styled(Container)`
  background-image: url("${BANNER}");
`;

const McMetricsWrap = styled.div`
  display: flex;
  align-items: flex-end;
  & > div {
    position: absolute;
    width: 100%;
    box-sizing: border-box;
  }
`;

const CarouselContainer = styled.div`
  & .carousel .thumbs-wrapper {
    display: none;
  }
  & .carousel.carousel-slider {
    overflow: visible;
  }

  & ${Content} {
    padding-bottom: 170px;
  }
  & .control-dots {
    margin-bottom: 130px;
  }
  @media screen and (max-width: 680px) {
    & ${ContainerJf} ${HeroTitle} {
      font-size: 29px;
    }
  }
  @media screen and (max-width: 900px) {
    & ${Grid} {
      grid-template-columns: 1fr;
    }
    & ${Grid}, & ${GridText} {
      padding: 0;
    }
    & ${GridImg} {
      margin: auto;
      max-width: 85%;
      padding-bottom: 0;
    }
    & ${GridVertialLine}, & ${EthImgContainer} {
      display: none;
    }
    & ${HeroTitle}, & ${McHeroParagraph} {
      padding: 0 8%;
    }
    & ${ButtonWrapper} {
      margin: auto;
    }
    & ${ContainerJf}, & ${ContainerJfmc} {
      background-size: auto 100%;
    }
    & ${Content} {
      padding-bottom: 220px;
    }
    & .control-dots {
      margin-bottom: 175px;
    }
  }
`;

export const HeroFallout = () => (
  <BlackOverlay>
    <ContainerFallout>
      <Content>
        <Grid>
          <GridImg>
            <img src={FALLOUT_LOGO} alt="" />
          </GridImg>
          <GridVertialLine />
          <GridText>
            <EthImgContainer>
              <img src={ETHICON} alt="" />
            </EthImgContainer>
            <Flag text="NOW EVOLVING" color="#02FD05" />
            <HeroTitle>
              <small>JUNGLE FREAKS:</small>
              FALLOUT FREAKS
            </HeroTitle>
            <McHeroParagraph style={{ maxWidth: "620px" }}>
              A collection of 15,000 evolved Freaks who suffer side effects from
              heavy exposure to the universe's rarest radioactive crystals.
            </McHeroParagraph>
            <ButtonWrapper>
              <a href="https://mint.falloutfreaks.io">
                <ButtonAction
                  style={{ backgroundColor: "#02FD05", color: "#000" }}
                >
                  EVOLVE NOW
                </ButtonAction>
              </a>
            </ButtonWrapper>
          </GridText>
        </Grid>
      </Content>
    </ContainerFallout>
  </BlackOverlay>
);

export const HeroJf = () => (
  <ContainerJf>
    <BlackAlphaOverlay>
      <Content>
        <Grid>
          <GridImg>
            <img src={LOGO} alt="" />
          </GridImg>
          <GridVertialLine />
          <GridText>
            <EthImgContainer>
              <img src={ETHICON} alt="" />
            </EthImgContainer>
            <Flag text="SOLD OUT" color="#fff" />
            <HeroTitle>
              <small>JUNGLE FREAKS:</small>
              GENESIS COLLECTION
            </HeroTitle>
            <McHeroParagraph style={{ maxWidth: "570px" }}>
              Jungle Freaks is a collection of 10,000 100% hand drawn Freaks
              that live and breathe on the Ethereum blockchain.
            </McHeroParagraph>
            <ButtonWrapper>
              <a
                href="https://discord.com/invite/junglefreaksnft"
                target="_blank"
              >
                <ButtonAction color="#F59E0C">
                  <img src={DISCORD} alt="" />
                  JOIN DISCORD
                </ButtonAction>
              </a>
            </ButtonWrapper>
          </GridText>
        </Grid>
      </Content>
    </BlackAlphaOverlay>
  </ContainerJf>
);

export const HeroJfmc = () => (
  <BlackOverlay>
    <ContainerJfmc>
      <Content>
        <Grid>
          <GridImg>
            <img src={JFMC_LOGO} alt="" />
          </GridImg>
          <GridVertialLine />
          <GridText>
            <EthImgContainer>
              <img src={ETHICON} alt="" />
            </EthImgContainer>
            <Flag text="SOLD OUT" color="#E61E28" textColor="#fff" />
            <HeroTitle>
              <small>JUNGLE FREAKS:</small>
              MOTOR CLUB
            </HeroTitle>
            <McHeroParagraph style={{ maxWidth: "620px" }}>
              A collection of 8,888 Hand Drawn Cars. 5 Different body styles
              with over 250+ unique traits. These NFT's act as a metaverse pass
              for car related games with embedded performance related metadata.
            </McHeroParagraph>
            <ButtonWrapper>
              <Link to="/jfmc">
                <ButtonAction color="#E61E28">LETS RIDE</ButtonAction>
              </Link>
            </ButtonWrapper>
          </GridText>
        </Grid>
      </Content>
    </ContainerJfmc>
  </BlackOverlay>
);

export default function HeroCaroussel() {
  return (
    <CarouselContainer>
      <Carousel
        showStatus={false}
        autoPlay={true}
        infiniteLoop={true}
        stopOnHover={false}
        interval={4000}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={100}
      >
        <HeroFallout />
        <HeroJf />
        <HeroJfmc />
      </Carousel>
      <McMetricsWrap>
        <McMetrics collection="genesis" />
      </McMetricsWrap>
    </CarouselContainer>
  );
}
